<template>
  <div>
    <template v-if="selectedFlag && merchant.name !== 'Entrepreneur Merchant' && merchant.is_self_signup && merchant.self_signup_verified === null">
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Your merchant profile is incomplete or still under review. You can still drop off your packages today!')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none font-semibold bg-cold-morning text-silver rounded flex items-center px-4 py-2 gap-1 h-btn">
            <feather-icon icon="PackageIcon" svgClasses="w-4 h-4"></feather-icon>
            <span class="text-sm">{{$route.name === 'merchant-returns-view' ? $t('Request Return') : $route.name === 'merchant-cash-collections-view'? $t('Collect Request') :  $t('Request Pickup')}}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
    <template v-else-if="selectedFlag && selected.filter((item) => item.is_counter_dropoff === true).length === 0 && selected.filter((item) => item.fulfillment_provider_metadata !== null).length === 0">
      <div class="cursor-pointer vs-con-loading__container" id="request-btn-loading">
        <div class="bg-primary rounded text-white text-sm font-semibold flex items-center px-4 py-2 gap-1 h-btn" @click="requestPickup()">
          <feather-icon icon="PackageIcon" svgClasses="w-4 h-4"></feather-icon>
          <span class="text-sm">{{$route.name === 'merchant-returns-view' ? $t('Request Return') : $route.name === 'merchant-cash-collections-view'? $t('Collect Request') :  $t('Request Pickup')}}</span>
        </div>
      </div>
    </template>
    <template v-else-if="selectedFlag && selected.filter((item) => item.is_counter_dropoff === true).length > 0">
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Selected orders are designated for \'Counter Drop Off.\' Please modify the order type in the \'Order Info\' section.')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none font-semibold bg-cold-morning text-silver rounded flex items-center px-4 py-2 gap-1 h-btn">
            <feather-icon icon="PackageIcon" svgClasses="w-4 h-4"></feather-icon>
            <span class="text-sm">{{$route.name === 'merchant-returns-view' ? $t('Request Return') : $route.name === 'merchant-cash-collections-view'? $t('Collect Request') :  $t('Request Pickup')}}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
    <template v-else-if="selectedFlag && selected.filter((item) => item.fulfillment_provider_metadata !== null).length > 0">
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Selected orders are designated for \'Fulfillment.\' Please modify the order type in the \'Order Info\' section.')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none font-semibold bg-cold-morning text-silver rounded flex items-center px-4 py-2 gap-1 h-btn">
            <feather-icon icon="PackageIcon" svgClasses="w-4 h-4"></feather-icon>
            <span class="text-sm">{{$route.name === 'merchant-returns-view' ? $t('Request Return') : $route.name === 'merchant-cash-collections-view'? $t('Collect Request') :  $t('Request Pickup')}}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select the order you want to request!')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none font-semibold bg-cold-morning text-silver rounded gap-1 flex items-center px-4 py-2 h-btn">
            <feather-icon icon="PackageIcon" svgClasses="w-4 h-4"></feather-icon>
            <span class="text-sm">{{$route.name === 'merchant-returns-view' ? $t('Request Return') : $route.name === 'merchant-cash-collections-view' ? $t('Collect Request') :  $t('Request Pickup')}}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
    <add-merchant-profile-info :addMerchantInfo="addMerchantInfo" @addMerchantInfo="addMerchantInfo = $event" @merchantUpdated="merchantUpdated = $event" @selected="selected = $event" />
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData'
import AddMerchantProfileInfo from './AddMerchantProfileInfo.vue'

export default {
  props: ['selected'],
  data () {
    return {
      selectedFlag: false,
      addMerchantInfo: false,
      merchant: {},
      merchantUpdated: false
    }
  },
  watch: {
    selected (val) {
      if (val.length !== 0 && val.filter(item => item.status === 'created' || item.status === 'pickup_rescheduled').length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
      this.$emit('selected', val)
    },
    merchantUpdated () {
      const merchantData = common.checkMerchantInLocalStorage(this)
      merchantData.then(results => {
        this.merchant = results.merchantData
      })
    }
  },
  components: {
    AddMerchantProfileInfo
  },
  methods: {
    requestPickup () {
      if (this.merchant.is_self_signup === true && this.merchant.name === 'Entrepreneur Merchant') {
        this.addMerchantInfo = true
      } else if (this.merchant.has_tax_profile === false) {
        this.$vs.dialog({
          color: 'danger',
          title: i18nData[this.$i18n.locale]['Oops!'],
          text: i18nData[this.$i18n.locale]['You don\'t have a tax profile to perform this action!'],
          acceptText: i18nData[this.$i18n.locale]['Choose Account type'],
          accept: this.goToAddTaxProfile
        })
      } else {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: '#request-btn-loading',
          scale: 0.45
        })
        const trackings = []
        let url = ''
        this.selected.forEach(element => {
          if (element.status === 'created' || element.status === 'pickup_rescheduled') {
            if (this.$route.name === 'merchant-cash-collections-view') {
              url = 'api/v1/tracking/cash-collections/'
              trackings.push({
                cash_collection_request:element.id,
                packages: element.packages.map(item => item.package.id),
                status: 'collection_requested'
              })
            } else {
              url = 'api/v1/tracking/'
              trackings.push({
                order:element.id,
                packages: element.packages.map(item => item.package.id),
                status: this.$route.name === 'merchant-returns-view' ? 'return_requested' : 'pickup_requested'
              })
            }
          }
          if (this.$route.name === 'merchant-cash-collections-view') {
            url = 'api/v1/tracking/cash-collections/'
          }
        })
        sendRequest(true, false, this, url, 'post', trackings, true,
          () => {
            this.$vs.notify({
              color:'success',
              title: i18nData[this.$i18n.locale]['Success'],
              text: i18nData[this.$i18n.locale]['Sent a pickup request. You will receive a notification once done.'],
              position: 'top-center'
            })
            this.$vs.loading.close('#request-btn-loading > .con-vs-loading')
            this.$emit('loadShipments')
            this.$emit('selected', [])
          },
          (error) => {
            common.notifyResponseError(this, error)
            this.$vs.loading.close('#request-btn-loading > .con-vs-loading')
          }
        )
      }
    },
    goToAddTaxProfile () {
      this.$router.push({
        name: 'merchant-account',
        query: {
          tab: 'account-details'
        }
      }).catch(() => {})
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.merchant = results.merchantData
    })
  }
}
</script>