<template>
  <div class="flex flex-col justify-between" style="min-height: calc(100dvh - (188px));">
    <div>
      <p class="text-black font-medium md:text-2xl text-xl md:mb-8 mb-5">{{ $t('New Quiz') }}</p>
      <div class="flex gap-2 items-start">
        <div @dragover.prevent="onDragOver" @dragstart="onDragStart" @dragend="onDragEnd" class="w-full flex flex-col md:gap-4 gap-3">
          <div v-for="(question, index) in questions" :key="index" draggable :data-index="index" @dragover.prevent @dragenter="onDragEnter(index)" :class="draggingIndex === index ? 'opacity-25' : ''" class="flex md:flex-row flex-col gap-2">
            <div class="question-container relative w-full">
              <div class="cursor-pointer">
                <div v-if="question.editable" class="md:p-8 px-5 py-7">
                  <input placeholder="Question" type="text" v-model="question.text" class="text-black font-medium md:text-2xl text-xl leading-tight w-full rounded md:py-3 py-2 md:px-5 px-3 border border-solid border-grey">
                  <div class="md:mt-6 mt-5">
                    <div class="flex flex-col md:gap-2 gap-1">
                      <div v-for="(option, optionIndex) in question.answer_option" :key="optionIndex" class="flex md:gap-2 gap-1">
                        <div class="relative w-full h-full">
                          <input placeholder="Answer" type="text" v-model="option.text" class="text-black md:text-lg text-sm bg-transparent w-full h-full rounded py-2 md:pl-3 md:pr-9 pl-2 pr-7 border border-solid border-grey">
                          <p v-if="question.answer_option.length > 2" @click="removeOption(index, optionIndex)" class="material-symbols-rounded text-grey absolute right-0 font-light text-2xl md:p-2 p-1 cursor-pointer">delete</p>
                        </div>
                        <label :for="`correct-${optionIndex}-${index}`" class="option-label select-none inline-flex items-center md:gap-2 gap-1 md:p-2 px-2 py-1 md:leading-none leading-loose text-grey cursor-pointer border border-solid border-grey rounded md:text-sm text-xs">
                          <input :name="`correct-${optionIndex}-${index}`" :id="`correct-${optionIndex}-${index}`" type="checkbox" :checked="option.is_correct" v-model="option.is_correct" class="hidden">
                          <p>{{ $t('Correct') }}</p>
                          <p class="material-symbols-rounded md:text-xl text-lg font-light">check</p>
                        </label>
                      </div>
                    </div>
                    <p @click="addNewOption(index)" class="md:mt-3 mt-2 md:text-base text-sm inline-block cursor-pointer">{{ $t('Add Option') }}</p>
                    <p v-if="!validQuestionFormat(index)" class="mt-1 md:text-sm text-xs leading-tight text-danger">{{ $t('Please make sure to fill in all fields and add at least two options, with at least one option correct.') }}</p>
                  </div>
                  <div class="md:mt-6 mt-4 flex gap-2">
                    <vs-button @click="saveQuestion(index)" color="primary" size="small" class="rounded md:text-base text-sm px-4">{{ $t('Save') }}</vs-button>
                    <vs-button @click="resetQuestion(index)" color="#8599B3" size="small" class="rounded md:text-base text-sm px-4">{{ $t('Cancel') }}</vs-button>
                  </div>
                </div>

                <div v-else @click="editQuestion(index)" class="md:p-8 px-5 py-7">
                  <p class="text-black font-medium md:text-2xl text-xl">{{ question.text }}</p>
                  <ul class="md:mt-6 mt-4 text-black md:text-lg px-2 flex flex-col md:gap-2 gap-1" style="list-style-type: disc; list-style-position: inside;">
                    <li v-for="(option, optionIndex) in question.answer_option" :key="optionIndex">
                      {{ option.text }}
                    </li>
                  </ul>
                </div>
              </div>
              <span class="material-symbols-rounded drag-indicator">drag_indicator</span>
            </div>

            <div :class="`flex md:flex-col gap-2 ${question.editable ? 'block' : 'hide-actions'}`">
              <vs-button @click="addNewQuestion(index)" color="#8599B3" :class="`${questions.length > 1 ? 'w-1/2' : 'w-full'} md:w-14 md:h-14 h-12 rounded flex items-center justify-center`">
                <span class="material-symbols-rounded text-xl font-medium">add</span>
              </vs-button>
              <vs-button v-if="questions.length > 1" @click="removeQuestion(index)" color="#8599B3" class="md:w-14 w-1/2 md:h-14 h-12 rounded flex items-center justify-center">
                <span class="material-symbols-rounded text-xl font-medium">delete</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <p class="text-black font-medium md:text-lg mb-2">{{ $t('Minimum success score') }}</p>
        <div class="flex gap-2 items-center">
          <input type="number" class="quiz-input-number" name="minimum success score" v-validate="'required|numeric|min_value:0|max_value:100'" v-model.number="passingScore">
          <span class="text-black font-medium text-lg">{{ '%' }}</span>
        </div>
        <p class="text-danger text-sm" v-show="errors.has('minimum success score')">{{ errors.first('minimum success score') }}</p>
      </div>
    </div>

    <div class="mt-8">
      <p v-if="questions.filter(item => item.editable).length > 0" class="md:text-sm text-xs leading-tight text-danger">{{ $t('Please make sure to save all questions.') }}</p>
      <div class="mt-2 flex gap-4">
        <vs-button @click="saveQuiz" color="primary" class="rounded md:text-lg font-semibold md:w-40 w-1/2">{{ $t('Save') }}</vs-button>
        <vs-button @click="resetQuiz" color="#8599B3" class="rounded md:text-lg font-semibold md:w-40 w-1/2">{{ $t('Cancel') }}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'

export default {
  data () {
    return {
      passingScore: 0,
      questions: [],
      createdQuestions: [],
      updatedQuestions: [],
      draggingIndex: null // For tracking the item being dragged
    }
  },
  methods: {
    addNewOption (questionIndex) {
      this.questions[questionIndex].answer_option.push({
        text: '',
        is_correct: false
      })
    },
    removeOption (questionIndex, optionIndex) {
      this.questions[questionIndex].answer_option.splice(optionIndex, 1)
    },

    addNewQuestion (questionIndex) {
      this.questions = [...this.questions.slice(0, questionIndex + 1), this.emptyQuestion(), ...this.questions.slice(questionIndex + 1)]
    },
    editQuestion (questionIndex) {
      this.questions[questionIndex].editable = true
      this.questions = [...this.questions]
    },
    removeQuestion (questionIndex) {
      this.questions.splice(questionIndex, 1)
    },
    saveQuestion (questionIndex) {
      if (this.validQuestionFormat(questionIndex)) {
        this.questions[questionIndex].editable = false
        this.questions = [...this.questions]
      }
    },
    resetQuestion (questionIndex) {
      this.questions[questionIndex] = this.emptyQuestion()
      this.questions = [...this.questions]
    },
    
    saveQuiz () {
      this.$validator.validateAll().then(result => {
        if (result && this.questions.filter(item => item.editable).length === 0) {
          const questions = []
          this.questions.forEach((item, index) => {
            const answer_option = []
            this.questions[index].answer_option.forEach(option => {
              answer_option.push({
                text: option.text,
                is_correct: option.is_correct,
                question: item.id ? item.id : null
              })
            })
            questions.push({
              id: item.id ? item.id : null,
              text: item.text,
              sort_order: index,
              answer_option
            })
          })

          this.createdQuestions = questions.filter(item => item.id === null)
          this.updatedQuestions = questions

          if (this.createdQuestions.length > 0) {
            this.sendCreatedQuestions(this.createdQuestions)
          } else {
            this.sendUpdatedQuestions(this.updatedQuestions)
          }
        }
      })
    },
    sendCreatedQuestions (questions) {
      const data = {
        chapter: Number(this.$route.query.chapterId),
        passing_score: this.passingScore,
        questions
      }
      sendRequest(true, false, this, 'api/v1/training/questions/', 'post', data, true,
        (response) => {
          this.updatedQuestions = [...this.updatedQuestions.filter(item => item.id !== null), ...response.data.questions]
          this.sendUpdatedQuestions(this.updatedQuestions)
        }
      )
    },
    sendUpdatedQuestions (questions) {
      const data = {
        chapter: Number(this.$route.query.chapterId),
        passing_score: this.passingScore,
        questions
      }
      sendRequest(false, false, this, 'api/v1/training/questions/', 'patch', data, true,
        () => {
          this.createdQuestions = []
          this.updatedQuestions = []
          this.loadQuestions()
        }
      )
    },
    
    resetQuiz () {
      this.$router.push({
        name: 'training-program',
        query: {
          tab: 'content',
          programId: this.$route.query.programId
        }
      }).catch(() => {})
    },

    validQuestionFormat (questionIndex) {
      if (this.questions[questionIndex].text !== '' && this.questions[questionIndex].answer_option.filter(item => item.text !== '').length >= 2 && this.questions[questionIndex].answer_option.filter(item => item.text === '').length === 0 && this.questions[questionIndex].answer_option.filter(item => item.is_correct === true).length >= 1) {
        return true
      }
    },
    emptyQuestion () {
      return {
        text: '',
        answer_option: [
          {
            text: '',
            is_correct: false
          },
          {
            text: '',
            is_correct: false
          }
        ],
        editable: true
      }
    },
    
    loadQuestions () {
      sendRequest(false, false, this, `api/v1/training/questions/?chapter=${this.$route.query.chapterId}&limit=100`, 'get', null, true,
        (response) => {
          this.passingScore = response.data.results.length > 0 ? response.data.results[0].passing_score : 0
          this.questions = response.data.results
          this.questions.forEach(item => { item.editable = false })
          if (this.questions.length === 0) {
            this.questions.push(this.emptyQuestion())
          }
        }
      )
    },

    // When the drag starts, store the index of the dragged item
    onDragStart (event) {
      this.draggingIndex = Number(event.target.dataset.index)
    },

    // When the drag ends, restore the item display and update the questions
    onDragEnd () {
      this.draggingIndex = null
    },

    // Handle the drag over event to determine the new position of the dragged item
    onDragOver (event) {
      event.preventDefault()
    },

    // This method is called when a dragged item enters another item's area
    onDragEnter (index) {
      if (this.draggingIndex !== index) {
        const draggedItem = this.questions[this.draggingIndex]
        this.questions.splice(this.draggingIndex, 1) // Remove the item from the array
        this.questions.splice(index, 0, draggedItem) // Insert it at the new position
        this.draggingIndex = index // Update the current dragging position
      }
    }
  },
  created () {
    this.loadQuestions()
  }
}
</script>

<style lang="scss">
.question-container {
  background: #F1F1F1;
  border: 1px solid #CDCDCD;
  border-radius: 10px;
  .drag-indicator {
    position: absolute;
    top: 0;
    left: calc(50% - 12px);
    transform: rotate(90deg);
    color: #CDCDCD;
    cursor: grab;
  }
}
.quiz-input-number {
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  width: 72px;
  padding: 8px;
  font-size: 14px;
  text-align: center;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
label.option-label {
  & > input:checked ~ p {
    color: #67C358;
  }
  &:has(input:checked) {
    background-color: #DEFFD9;
    border-color: #67C358 !important;
  }
}
.hide-actions {
  opacity: 0;
  visibility: hidden;
  @media (max-width: 767px) {
    display: none !important;
  }
}
</style>