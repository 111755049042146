<template>
  <div class="text-left">
    <div>
      <h3 class="mb-6 text-2xl">{{ $t('Integration Instructions') }}</h3>
      <div class="list-item text-2xl text-black">
        <p class="font-bold">
          1. {{ $t('Install') }}
          <a href="https://apps.shopify.com/shipblu" target="_blank">{{ $t('ShipBlu App') }}</a>
        </p>
        <li>{{ $t('Search for our app in Shopify App Store and Install it') }}.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-Shopify-step1.png">
      </div>
      <div class="list-item text-2xl text-black">
        <p class="font-bold">
          2. {{ $t('Add the API Key') }}
        </p>
        <li>{{ $t('Navigate to the top of this page and click on') }} <span class="font-bold">{{ $t("Generate API key") }}</span>.</li>
        <li>{{ $t('Open ShipBlu App settings page on shopify and Add the generated API key') }}.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-Shopify-step2.png">
      </div>
      <div class="list-item text-2xl text-black">
        <p class="font-bold">
          3. {{ $t('Set Your Preferences') }}
        </p>
        <li>{{ $t('From the App settings, you can customize the shipping preference to align with your business needs') }}.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-Shopify-step3.png">
      </div>
      <div class="list-item text-2xl text-black">
        <p class="font-bold">
          4. {{ $t('Sync Orders') }}
        </p>
        <li>{{ $t('Select the orders and click on') }} <span class="font-bold">"Send to ShipBlu"</span>.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-Shopify-step4.png">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['APIKey'],
  data () {
    return {
      text: ''
    }
  }
}
</script>
<style>
.list-item{
  border-bottom: 1px solid rgba(0,0,0,.08);
  padding: 5px;
}
</style>