<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Cash Collection')}}</h2>
        <p class="mt-2">{{$t('Cash-only orders. No shipment to be delivered.')}}</p>
      </div>
    </div>
    <table-filter class="mb-4" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>

    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      :filters="filters"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="cashRequests"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="flex items-center flex-wrap gap-3 whitespace-nowrap">
          <export-button></export-button>
          <print-label-button :selected="selected"></print-label-button>
          <cancel-shipments-button v-if="$store.state.AppActiveUser.userRole === 'customer-support'" class="mr-4 float-left whitespace-nowrap" :selected="selected" @selected="selected = $event" @loadShipments="loadCashCollection"></cancel-shipments-button>
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Info')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-cash-collections`, params: { type: 'cash-collections', orderID: data[indextr].id }}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].merchant.name }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo" class="order-6">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].location" class="order-7">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].customer.address.zone.name) }}</p>
            <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">{{ $t(data[indextr].customer.address.zone.city.name) }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].amount" class="order-5">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Amount')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].amount && parseInt(data[indextr].amount) > 0 ? ((Number(data[indextr].amount)).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-12" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event)">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item
                      @click="viewShipment(data[indextr])"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="EyeIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('View')}}</span>
                      </span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
    <print :selected="selected"></print>
  </div>
</template>

<script>
import Print from '../merchant/components/PrintLabels.vue'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import CancelShipmentsButton from '../admin/components/CancelShipmentsButton.vue'
import TableFilter from '../merchant/components/TableFilter.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'

export default {
  data () {
    return {
      selected: [],
      cashRequests: [],
      type: '',
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: ''
        },
        {
          name: 'created',
          value: 'created',
          color: 'bg-dark'
        },
        {
          name: 'collection requested',
          value: 'collection_requested',
          color: 'bg-warning'
        },
        {
          name: 'out for collection',
          value: 'out_for_collection',
          color: 'bg-primary'
        },
        {
          name: 'collection attempted',
          value: 'collection_attempted',
          color: 'bg-warning'
        },
        {
          name: 'collected',
          value: 'collected',
          color: 'bg-success'
        },
        {
          name: 'failed',
          value: 'failed',
          color: 'bg-danger'
        },
        {
          name: 'cancelled',
          value: 'cancelled',
          color: 'bg-dark'
        }
      ],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      filters: [],
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false
    }
  },
  watch: {
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadCashCollection()
      }
    },
    '$route.params.warehouseID' () {
      this.maximumItems = process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
      this.loadCashCollection()
    }
  },
  methods: {
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadCashCollection(this.paginationObj[val])
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadCashCollection()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCashCollectionsSearch)
    },
    loadCashCollectionsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadCashCollection()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-cash-collections-view`,
        query: {
          filter: this.filters
        }
      }).catch(() => {})
      this.loadCashCollection()
    },
    loadCashCollection (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?status=${this.filters}&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&warehouse_code=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}`
      sendRequest(true, false, this, `api/v1/cash-collections/${query}`, 'get', null, true, 
        (response) => {
          this.cashRequests = response.data.results
          this.tableLoader = false
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    }
  },
  components: {
    Print,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    PrintLabelButton,
    CancelShipmentsButton,
    TableFilter,
    ShipbluCursorPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadCashCollection()
  }
}
</script>

<style>
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
