<template>
  <div>
    <div @click="filterDropdownFlag = false" v-if="filterDropdownFlag === true" class="fixed inset-0 bg-transparent filter-z-index"></div>
    <div class="relative lg:max-w-sm" style="min-width: 100px;" :class="filterDropdownFlag ? 'filter-z-index' : ''">
      <div @click="filterDropdownFlag = !filterDropdownFlag" class="h-btn flex gap-2 justify-between items-center cursor-pointer bg-white rounded border-grey border border-solid p-2 whitespace-no-wrap overflow-hidden">
        <div class="flex items-center gap-2">
          <span v-if="icon" class="material-symbols-outlined text-lg">{{ icon }}</span>
          <p class="truncate capitalize font-medium text-sm">{{filters.length === 0 ? filterLabel && filterLabel.length > 0 ? $t(filterLabel): $t('All Selected') : filters.map(item => $t(item.replaceAll('_', ' '))).join(', ')}}</p>
        </div>
        <div class="text-right flex items-center">
          <feather-icon class="w-4 h-4" :icon="filterDropdownFlag ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
        </div>
      </div>
      <div v-if="filterDropdownFlag" class="absolute w-76 left-0 bg-white p-2 rounded border-grey border border-solid" style="top: 40px;">
        <div @click="changeFilter(item.value)" v-for="item in statusFilters" :key="item.index">
          <vs-checkbox class="mb-2 " :checked="filters.length === 0 && item.value === 'all' && checkFlag  ? true : filters.includes(item.value)" :vs-value="item.value">
            <span class="capitalize">{{ $t(item.name) }}</span>
          </vs-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['statusFilters', 'filters', 'filterLabel', 'icon'],
  data () {
    return {
      filterDropdownFlag: false,
      checkFlag:true
    }
  },
  watch :{
    '$route.query.flag' (val) {
      this.checkFlag = val && val.length === 0
    }
  },
  methods: {
    changeFilter (type) {
      this.$emit('filterValue', type)
      this.$emit('changeFilter')
    }
  }
}
</script>

<style>
.filter-z-index {
  z-index: 9999;
  user-select: none;
}
</style>