<template>
  <div class="flex flex-col justify-between" style="min-height: calc(100dvh - 330px);">
    <div v-if="!$route.query.programId || editable === true" class="mt-10 grid md:grid-cols-2 grid-cols-1 md:gap-12 gap-8">
      <div>
        <p class="font-medium md:text-lg text-black">{{ 'Program Name' }}</p>
        <div class="md:mt-4 mt-2">
          <input autocomplete="nofill" class="w-full input-order" v-validate="'required'" v-model="program.name" name="program name" />
          <span class="text-danger text-sm" v-show="errors.has('program name')">{{ errors.first('program name') }}</span>
        </div>
      </div>
      <div>
        <p class="font-medium md:text-lg text-black">{{ 'Agent Type' }}</p>
        <div class="md:mt-4 mt-2">
          <v-select multiple autocomplete="nofill" class="w-full select-border" v-validate="'required'" v-model="program.agent_type" name="agent type" label="name" :options="agentTypes"></v-select>
          <span class="text-danger text-sm" v-show="errors.has('agent type')">{{ errors.first('agent type') }}</span>
        </div>
      </div>
      <div>
        <p class="font-medium md:text-lg text-black">{{ 'Start Date' }}</p>
        <div class="md:mt-4 mt-2 relative">
          <datepicker :disabledDates="disabledDates" v-validate="'required'" name="date" v-model="program.start_date" class="w-full bg-transparent"></datepicker>
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-10">
            <span class="material-symbols-outlined">calendar_today</span>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('date')">{{ errors.first('date') }}</span>
        </div>
      </div>
      <div>
        <p class="font-medium md:text-lg text-black">{{ 'Program Duration' }}</p>
        <div class="md:mt-4 mt-2">
          <input autocomplete="nofill" class="w-24 input-order" v-model="program.duration" v-validate="'required|numeric'" name="days" />
          <span class="md:ml-4 ml-2 text-black md:text-lg font-medium">{{ 'Days from agent creation date ' }}</span>
        </div>
        <span class="text-danger text-sm" v-show="errors.has('days')">{{ errors.first('days') }}</span>
      </div>
    </div>

    <div v-else class="mt-10 grid sm:grid-cols-2 grid-cols-1 md:gap-12 gap-8">
      <div class="font-medium text-lg">
        <p class="text-black">{{ 'Program Name' }}</p>
        <p class="md:mt-4 mt-2 text-grey-dark">{{ program.name }}</p>
      </div>
      <div class="font-medium text-lg">
        <p class="text-black">{{ 'Agent Type' }}</p>
        <div class="md:mt-4 mt-2 text-grey-dark capitalize">
          <p v-for="type in program.agent_type" :key="type.index">{{ type.name }}</p>
        </div>
      </div>
      <div class="font-medium text-lg">
        <p class="text-black">{{ 'Start Date' }}</p>
        <p class="md:mt-4 mt-2 text-grey-dark">{{ common.formatDate(program.start_date) }}</p>
      </div>
      <div class="font-medium text-lg">
        <p class="text-black">{{ 'Program Duration' }}</p>
        <p class="md:mt-4 mt-2 text-grey-dark">{{ program.duration + ' Days from agent creation date' }}</p>
      </div>
    </div>
    <vs-button v-if="!$route.query.programId || editable === true" @click="saveTrainingProgram" class="w-32 mt-8">{{ 'Save' }}</vs-button>
    <vs-button v-else-if="$route.query.programId || editable === false" @click="editable = true" class="w-40 mt-8">{{ 'Edit Details' }}</vs-button>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
import common from '../../../assets/utils/common'

export default {
  data () {
    return {
      disabledDates: {
        to: new Date()
      },
      common,
      agentTypes: [
        {
          name: 'Customer Agent',
          value: 'customer_agent'
        },
        {
          name: 'Merchant Agent',
          value: 'merchant_agent'
        },
        {
          name: 'Customer Merchant Agent',
          value: 'customer_merchant_agent'
        }
      ],
      agentTypesDic: {'customer_agent': 'Customer Agent', 'merchant_agent': 'Merchant Agent', 'customer_merchant_agent': 'Customer Merchant Agent'},
      program: {
        agent_type: [],
        start_date: '',
        duration: 0,
        passing_score: 0
      },
      editable: false
    }
  },
  components: {
    vSelect,
    Datepicker
  },
  methods: {
    loadTrainingProgram () {
      sendRequest(true, false, this, `api/v1/training/programs/${this.$route.query.programId}/`, 'get', null, true,
        (response) => {
          this.program = response.data
          const agentTypes = this.program.agent_type
          this.program.agent_type = []
          agentTypes.forEach((item) => {
            this.program.agent_type.push({
              name: this.agentTypesDic[item],
              value: item
            })
          })
          this.$emit('program', this.program)
        }
      )
    },
    saveTrainingProgram () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.program.start_date = this.program.start_date ? new Date(this.program.start_date).toISOString().split('T')[0] : ''
          this.program.duration = Number(this.program.duration)
          this.program.agent_type = this.program.agent_type.filter(item => item.value).map(element => element.value)
          if (!this.$route.query.programId) {
            this.createTrainingProgram()
          } else {
            this.updateTrainingProgram()
          }
        }
      })
    },
    createTrainingProgram () {
      sendRequest(false, false, this, 'api/v1/training/programs/', 'post', this.program, true,
        (response) => {
          this.editable = false
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: i18nData[this.$i18n.locale]['Training Program'] + i18nData[this.$i18n.locale][' has been created successfully!'],
            position: 'top-center'
          })
          this.$router.push({
            query: {
              programId: response.data.id,
              tab: 'details'
            }
          })
          this.loadTrainingProgram()
        }
      )
    },
    updateTrainingProgram () {
      sendRequest(false, false, this, `api/v1/training/programs/${this.$route.query.programId}/`, 'patch', this.program, true,
        () => {
          this.editable = false
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: i18nData[this.$i18n.locale]['Training Program'] + i18nData[this.$i18n.locale][' has been created successfully!'],
            position: 'top-center'
          })
          this.loadTrainingProgram()
        }
      )
    }
  },
  created () {
    if (this.$route.query.programId) {
      this.loadTrainingProgram()
    } else {
      this.program = {
        agent_type: [],
        start_date: '',
        duration: 0,
        passing_score: 0
      }
    }
  }
}
</script>

<style lang="scss">
.v-select {
  &.select-border {
    ul {
      min-width: fit-content;
    }
    ::placeholder {
      color: #9DADC2 !important;
    }
    background-color: #FFFF;
    border: solid 1px #CDCDCD;
    border-radius: 6px;
    .vs__dropdown-toggle {
      border: none;
    }
  }
  .feather-chevron-down, .feather-x {
    stroke: #6C84A3;
  }
  ul {
    margin-top: 4px;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
  }
}
</style>
