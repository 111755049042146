<template>
  <div class="shipments-page" id="merchant-returns">
    <h2 class="mb-4">{{$t('Returns')}}</h2>
    <table-filter class="mb-4" v-if="$route.name !== 'admin-warehouse-orders'" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @searchDate="handleSearchDate"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :searchDate="$route.name === 'admin-returns-view' || $route.name === 'customer-support-returns-view' ? true : false"
      search
      pagination
      :data="returns"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="flex flex-wrap items-center gap-3 whitespace-nowrap">
          <export-button />
          <print-label-button :selected="selected"></print-label-button>
          <cancel-shipments-button :selected="selected" @selected="selected = $event" @loadShipments="loadShipments"></cancel-shipments-button>
          <download-button :selected="selected"></download-button>
        </div>
      </template>
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('CoD')}}</shipblu-th>
        <shipblu-th>{{$t('Received')}}</shipblu-th>
        <shipblu-th>{{$t('Return Attempts')}}</shipblu-th>
        <shipblu-th>{{$t('On Hold')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Returned On')}}</shipblu-th>
        <shipblu-th class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
         :to="{ name: `${$store.state.AppActiveUser.userRole}-returns`, params: { type: 'returns', orderID: data[indextr].id }}">
         <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].createdOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">
              {{ common.splitYearOfDate(data[indextr].created) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].merchant.name }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].customerInfo" v-if="data[indextr].customer">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].location">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">
              {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Packages')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].packages.length }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-11" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Package Size')}}</p>
            <p class="sm:text-base text-sm" v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].cashOnDelivery">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Amount')}}</p>
            <p class="sm:text-base text-sm">
              {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-12" :data="data[indextr].createdOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Received')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].pickup_date? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-15" :data="data[indextr].attempts">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Attempts')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].return_attempts}} / 3
            </p>
          </shipblu-td>

          <shipblu-td class="order-14" :data="data[indextr].on_hold">
            <template v-if="data[indextr].on_hold !== null">
              <div class="bg-warning h-3 w-3 inline-block rounded-full mr-2"></div>
              <span>On Hold</span>
            </template>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-13" :data="data[indextr].returnedOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Returned')}}</p>
            <p class="sm:text-base text-sm">
            {{ data[indextr].returned_date ? new Date(data[indextr].returned_date).toLocaleDateString('fr-CA') : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item @click="viewReturn(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View Return')}}</span>
                    </span>
                  </vs-dropdown-item>

                  <!-- ALLOW CANCEL IF NOT DELIVERED OR RETURNED -->
                  <vs-dropdown-item
                    v-if="data[indextr].status === 'created'"
                    @click="updateShipmentStatus(data[indextr], 'cancel this order', 'cancelled')"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="XCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Cancel Request')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="data[indextr].status === 'returned'"
                    @click="getInvoice(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="DownloadIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Download Invoice')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="$store.state.AppActiveUser.userRole === 'admin'"
                    @click="blockCustomer(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="UserXIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Block Customer')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
    <block-customer :blockCustomerModal="blockCustomerModal" @blockCustomerModal="blockCustomerModal = $event" :data="customer" @loadShipments="loadShipments"></block-customer>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import Shipment from '../merchant/components/Shipment.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import BlockCustomer from '../admin/components/BlockCustomer.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import CancelShipmentsButton from './components/CancelShipmentsButton.vue'
import TableFilter from '../merchant/components/TableFilter.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'
import DownloadButton from './components/DownloadButton.vue'

export default {
  props: ['warehouseID'],
  data () {
    return {
      userRole: this.$store.state.AppActiveUser.userRole,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      returns: [],
      type: '',
      common,
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'return requested',
          value: 'return_requested'
        },
        {
          name: 'out for pickup',
          value: 'out_for_pickup'
        },
        {
          name: 'return attempted',
          value: 'return_attempted'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'in transit & en route',
          value: 'in_transit, en_route'
        },
        {
          name: 'out for return',
          value: 'out_for_return'
        },
        {
          name: 'On Hold',
          value: 'on_hold'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      searchVal: '',
      filters: [],
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      trackingData: {},
      trackingStatus: '',
      searchInProgress: false,
      searchedValue: ' ',
      blockCustomerModal: false,
      customer: {},
      fromDate: '',
      toDate: '',
      criteria: '',
      tableLoader: false
    }
  },
  watch: {
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadShipments()
      }
    }
  },
  methods: {
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadShipments(this.paginationObj[val])
    },
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadShipments()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    loadShipments (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?current_warehouse=${this.$route.params.warehouseID ? this.$route.params.warehouseID : ''}&${this.filters.includes('on_hold') ? 'on_hold=true' : `status=${this.filters}`}&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/returns/${query}`, 'get', null, true, 
        (response) => {
          this.returns = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.loadShipments()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadReturnsSearch)
    },
    loadReturnsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadShipments()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all' || (this.filterValue === 'on_hold' && this.filters.includes('on_hold'))) {
        this.filters = []
        if (this.filterValue === 'on_hold' && !this.filters.includes('on_hold')) {
          this.filters.push('on_hold')
        }
      } else if (this.filters.includes('on_hold')) {
        this.filters.splice(this.filters.indexOf('on_hold'), 1)
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-returns-view`,
        query: {
          filter: this.filters
        }
      }).catch(() => {})
      this.loadShipments()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    updateShipmentStatus (data, sentence, status) {
      this.trackingData = data
      this.trackingStatus = status
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: `Are you sure you want to ${sentence} ?`,
        acceptText: 'Yes',
        accept: this.confirmUpdateShipmentStatus
      })
    },
    blockCustomer (data) {
      this.customer = data
      this.blockCustomerModal = true
    },
    confirmUpdateShipmentStatus (data, status) {
      let trackings = []
      if (data) {
        trackings = [
          {
            order: data.id,
            packages: data.packages.map(item => item.package.id),
            status
          }
        ]
      } else {
        trackings = [
          {
            order: this.trackingData.id,
            packages: this.trackingData.packages.map(item => item.package.id),
            status: this.trackingStatus
          }
        ]
      }
      sendRequest(false, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Return'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadShipments()
        }
      )
    },
    viewReturn (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-returns`,
        params: {
          type: 'returns',
          orderID: data.id
        }
      }).catch(() => {})
    },
    getInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/merchants/${data.merchant.id}/invoices/?invoiceorder__order=${data.id}`, 'get', null, true, 
        (response) => {
          this.downloadInvoice(response.data.results[0].id)
        }
      )
    },
    downloadInvoice (invoiceId) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${invoiceId}/pdf/`, 'get', null, true, 
        (response) => {
          const file_URl = response.data.file_url
          window.open(file_URl, '_blank')
        }
      )
    }
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  components: {
    Shipment,
    BlockCustomer,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    PrintLabelButton,
    CancelShipmentsButton,
    TableFilter,
    ShipbluCursorPagination,
    DownloadButton
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadShipments()
  }
}
</script>

<style lang="scss">
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
