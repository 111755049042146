<template>
  <div class="shipments-page">
    <h2 class="mb-4">{{$t('Delivery Orders')}}</h2>
    <table-filter class="mb-4" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    <shipblu-table
     :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @searchDate="handleSearchDate"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      searchDate
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="flex flex-wrap items-center gap-3 whitespace-nowrap">
          <export-button></export-button>
          <cancel-shipments-button v-if="$store.state.AppActiveUser.userRole !== 'sales'" :selected="selected" @selected="selected = $event" @loadShipments="loadMerchantShipments"></cancel-shipments-button>
        </div>
      </template>

      <template slot="thead">
        <shipblu-th >{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('CoD')}}</shipblu-th>
        <shipblu-th>{{$t('Pickup Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Delivered On')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: { type: 'delivery-orders', orderID: data[indextr].id}}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].createdOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">
              {{ common.splitYearOfDate(data[indextr].created) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].customerInfo">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].location">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">
              {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Packages')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].packages.length }}
            </p>
          </shipblu-td>
          
          <shipblu-td class="order-9" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Package Size')}}</p>
            <p class="sm:text-base text-sm" v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>
          
          <shipblu-td class="order-12" :data="data[indextr].cashOnDelivery">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Amount')}}</p>
            <p class="sm:text-base text-sm">
              {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].pickupDate">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Received')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].pickup_date? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-11" :data="data[indextr].deliveredOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Delivered On')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].delivered_date && data[indextr].status === 'delivered' ? new Date(data[indextr].delivered_date).toLocaleDateString('fr-CA') : '' }}
            </p>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" :noOptions="true" @changePageNumber="changePageNumber($event)" />
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ExportButton from '../../headOfFleet/components/ExportButton.vue'
import CancelShipmentsButton from '../../admin/components/CancelShipmentsButton.vue'
import TableFilter from '../../merchant/components/TableFilter.vue'
import ShipbluCursorPagination from '../../../layouts/components/ShipbluCursorPagination.vue'


export default {
  data () {
    return {
      common,
      merchantUID: this.$store.state.AppActiveUser.uid,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'pickup requested',
          value: 'pickup_requested'
        },
        {
          name: 'out for pickup',
          value: 'out_for_pickup'
        },
        {
          name: 'pickup rescheduled',
          value: 'pickup_rescheduled'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'in transit & en route',
          value: 'in_transit, en_route'
        },
        {
          name: 'out for delivery',
          value: 'out_for_delivery'
        },
        {
          name: 'delivery attempted',
          value: 'delivery_attempted'
        },
        {
          name: 'delivered',
          value: 'delivered'
        },
        {
          name: 'RTO Requested',
          value: 'rto_requested'
        },
        {
          name: 'On Hold',
          value: 'on_hold'
        },
        {
          name: 'out for return',
          value: 'out_for_return'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        }
      ],
      filters: [],
      merchant: {},
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      file_URl: '',
      trackingData: {},
      trackingStatus: '',
      tourMerchantOrders: '',
      governorateId: '',
      searchInProgress: false,
      searchedValue: ' ',
      fromDate: '',
      toDate: '',
      tableLoader: false,
      criteria: ''
    }
  },
  watch: {
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadMerchantShipments()
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadMerchantShipments(this.paginationObj[val])
    },
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadMerchantShipments (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?merchant=${this.$route.params.merchantID}&is_exchange=false&cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&${Array.isArray(this.filters) && this.filters.includes('on_hold') ? 'on_hold=true' : this.filters.includes('rto_requested') ? 'rto_requested=true' : `status=${this.filters}`}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/delivery-orders/${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.loadMerchantShipments()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadMerchantShipments()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all' || (this.filterValue === 'rto_requested' && this.filters.includes('rto_requested')) || (this.filterValue === 'on_hold' && this.filters.includes('on_hold'))) {
        this.filters = []
        if (this.filterValue === 'rto_requested' && !this.filters.includes('rto_requested')) {
          this.filters.push('rto_requested')
        } else if (this.filterValue === 'on_hold' && !this.filters.includes('on_hold')) {
          this.filters.push('on_hold')
        }
      } else if (this.filters.includes('rto_requested')) {
        this.filters.splice(this.filters.indexOf('rto_requested'), 1)
      } else if (this.filters.includes('on_hold')) {
        this.filters.splice(this.filters.indexOf('on_hold'), 1)
      }
      this.selected = []
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          filter: this.filters
        }
      }).catch(() => {})
      this.loadMerchantShipments()
    },
    viewShipment (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-${data.tracking_number[0] === '2' || (data.tracking_number[0] === '3' && data.tracking_number[1] === '2') ? 'returns' : data.tracking_number[0] === '4' ? 'cash-collections' : 'delivery-orders'}`,
        params: {
          orderID: data.id,
          type: data.tracking_number[0] === '2' || (data.tracking_number[0] === '3' && data.tracking_number[1] === '2') ? 'returns' : data.tracking_number[0] === '4' ? 'cash-collections' : 'delivery-orders'
        }
      }).catch(() => {})
    }
  },
  components: {
    VuePerfectScrollbar,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    CancelShipmentsButton,
    TableFilter,
    ShipbluCursorPagination
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadMerchantShipments()
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
