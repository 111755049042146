<template>
  <div id="page-user-view" class="p-6">
    <h2 class="mb-4">{{$t('Merchant Invoices')}}</h2>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="invoices"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Invoice Date')}}</shipblu-th>
        <shipblu-th>{{$t('Invoice Number')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Subtotal')}}</shipblu-th>
        <shipblu-th>{{$t('VAT')}}</shipblu-th>
        <shipblu-th>{{$t('Total')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'sales'">{{$t('Download Invoice')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].created">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Invoice Date')}}</p>
            <p class="sm:text-base text-sm">
              {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].number }}
                <span @click="copied(data[indextr].number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].bill_to_customer_name">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].bill_to_customer_name }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].subtotal">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Subtotal')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].vat">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Vat')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].total">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Total')}}</p>
            <p class="sm:text-base text-sm">
              {{ Number(data[indextr].total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" v-if="$store.state.AppActiveUser.userRole !== 'sales'" :data="data[indextr].pdf_link">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Download Invoice')}}</p>
            <a class="sm:text-base text-sm" @click="downloadInvoice(data[indextr])" target="_blank">{{$t('Download')}}</a>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluCursorPagination from '../../../layouts/components/ShipbluCursorPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      invoices: [],
      selected: [],
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      file_URl: '',
      tableLoader: false
    }
  },
  methods: {
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadInvoices(this.paginationObj[val])
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadInvoices()
    },
    downloadInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    loadInvoices (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.tableLoader = true
      const query = `?cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/merchants/${this.$route.params.merchantID}/invoices/${query}`, 'get', null, true,
        (response) => {
          this.invoices = response.data.results
          this.tableLoader = false
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCursorPagination
  },
  created () {
    this.loadInvoices()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
