<template>
  <div>
    <div class="mt-4">
      <head-component v-if="$route.query.tab !== 'awaiting-rezoning'" :noActions="true" :statusFilters="$route.query.tab === 'in-progress' ? inProgressStatusFilters : statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
    </div>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="ordersData"
      :tableLoader="tableLoader"
    >

      <template slot="thead">
        <shipblu-th >{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Info')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Phone')}}</shipblu-th>
        <shipblu-th v-if="$route.query.tab !== 'awaiting-rezoning'">{{$t('CoD')}}</shipblu-th>
        <shipblu-th v-if="$route.query.tab !== 'awaiting-rezoning'">{{$t('Attempts')}}</shipblu-th>
        <shipblu-th>{{$t('Feedback')}}</shipblu-th>
        <shipblu-th>{{$t('Your Action')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: { type: 'delivery-orders', orderID: data[indextr].id}}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>
          <shipblu-td :data="data[indextr].createdOn">
            <p v-if="$route.query.tab === 'awaiting-rezoning'">
              {{ data[indextr].created ? common.splitYearOfDate(data[indextr].created) : 'N/A' }}
            </p>
            <p v-else>
              {{ getAttemptDate(data[indextr]) }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone">
            {{ data[indextr].customer.phone }}
          </shipblu-td>
          
          <shipblu-td v-if="$route.query.tab !== 'awaiting-rezoning'" :data="data[indextr].cashOnDelivery">
            {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}
          </shipblu-td>

          <shipblu-td v-if="$route.query.tab !== 'awaiting-rezoning'" :data="data[indextr].cashOnDelivery">
            {{ data[indextr].delivery_attempts + ' / 3' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].cashOnDelivery">
            {{ $route.query.tab === 'awaiting-rezoning' ? 'Unzoned' : getAttemptReason(data[indextr])[getAttemptReason(data[indextr]).length - 1].comment }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].cashOnDelivery">
            <div v-if="$route.query.tab === 'awaiting-action'" class="flex gap-2 justify-center items-center" @click="removeAttribute($event)">
              <vs-button class="whitespace-no-wrap w-44 py-2 px-4" v-if="getAttemptReason(data[indextr])[getAttemptReason(data[indextr]).length - 1].comment.includes('Customer refused to accept the shipment') || getAttemptReason(data[indextr])[getAttemptReason(data[indextr]).length - 1].comment.includes('Customer is avoiding the shipment')" @click="showDeliverAgainMessage ? openModal(data[indextr], 'deliver_again') :  addAction(data[indextr], 'deliver_again')">{{$t('Deliver Again')}}</vs-button>
              <vs-button class="whitespace-no-wrap w-44 py-2 px-4" v-else-if="getAttemptReason(data[indextr])[getAttemptReason(data[indextr]).length - 1].comment.includes('Wrong COD')" @click="openModal(data[indextr], 'change_cod')">{{$t('Change COD')}}</vs-button>
              <vs-button class="whitespace-no-wrap w-44 py-2 px-4" v-else-if="getAttemptReason(data[indextr])[getAttemptReason(data[indextr]).length - 1].comment.includes('Wrong Phone Number') || getAttemptReason(data[indextr])[getAttemptReason(data[indextr]).length - 1].comment.includes('Phone number is not related to the customer')" @click="openModal(data[indextr], 'change_phone_number')">{{$t('Change Number')}}</vs-button>
              <vs-button class="whitespace-no-wrap w-44 py-2 px-4" v-else-if="getAttemptReason(data[indextr])[getAttemptReason(data[indextr]).length - 1].comment.includes('Customer wants to open the package')" @click="addAction(data[indextr], 'allow_open_packages')">{{$t('Allow Open')}}</vs-button>
            </div>
            <div v-else-if="$route.query.tab === 'awaiting-rezoning'" class="flex gap-2 justify-center" @click="removeAttribute($event)">
              <vs-button class="whitespace-no-wrap py-2 px-4" @click="openRezoningModal(data[indextr])">{{$t('Rezoning')}}</vs-button>
              <vs-button type="border" color="danger" class="px-2 py-1 items-center" @click="addTracking(data[indextr])">{{$t('Cancel')}}</vs-button>
            </div>
            <vs-button v-else type="border" color="danger" class="ml-2 px-2 pt-2 pb-1 leading-none" @click="removeAttribute($event), cancelAndReturn(data[indextr], 'cancel_and_return')"><span class="material-icons text-xl font-semibold">sync_alt</span></vs-button>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <action-center-modal :actionModal="actionModal" @loadData="loadData" :rowData="rowData" :actionType="actionType" @actionModal="actionModal = $event"/>
    <rezoning-modal :rezoningModal="rezoningModal" @loadData="loadData" :rowData="rowData" :actionType="actionType" @rezoningModal="rezoningModal = $event"/>
  </div>
</template>

<script>
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import TableFilter from './TableFilter.vue'
import { sendRequest } from '../../../http/axios/requestHelper'
import common from '@/assets/utils/common'
import HeadComponent from './Header.vue'
import ActionCenterModal from './ActionCenterModal.vue'
import RezoningModal from './RezoningModal.vue'
import i18nData from '../../../i18n/i18nData'

export default {
  data () {
    return {
      rowData: {
        customer: {
          address: {}
        }
      },
      cancelAndReturnAction: '',
      cancelAndReturnData: {},
      showDeliverAgainMessage: false,
      actionModal: false,
      filters: '',
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'Wrong COD',
          value: 'Wrong COD'
        },
        {
          name: 'Wrong Phone Number',
          value: 'Wrong Phone Number'
        },
        {
          name: 'Customer wants to open the package',
          value: 'Customer wants to open the package'
        }
      ],
      inProgressStatusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'Customer\'s phone is turned off',
          value: 'Customer\'s phone is turned off'
        },
        {
          name: 'Customer is not answering our calls',
          value: 'Customer is not answering our calls'
        },
        {
          name: 'No one at home',
          value: 'No one at home'
        },
        {
          name: 'Customer Rescheduled',
          value: 'Customer Rescheduled'
        }
      ],
      ordersData: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' ',
      filterValue: '',
      actionType: '',
      rezoningModal: false,
      common
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadData()
    }
  },
  methods: {
    addTracking (order) {
      sendRequest(true, false, this, 'api/v1/tracking/', 'post', [{order: order.id, status: 'cancelled'}], true,
        () => {
          this.closeModal()
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.$emit('loadOrder', 'trackingCardLoading')
        }
      )
    },
    openRezoningModal (data) {
      this.rezoningModal = true
      this.rowData = data
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    openModal (data, actionType) {
      this.actionType = actionType
      this.actionModal = true
      this.rowData = data
    },
    getAttemptReason (data) {
      return data.tracking_events.filter(item => item.status === 'delivery_attempted')
    },
    getAttemptDate (data) {
      return common.splitYearOfDate(data.tracking_events.filter(item => item.status === 'delivery_attempted')[data.tracking_events.filter(item => item.status === 'delivery_attempted').length - 1].created)
    },
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.selected = []
      this.currentPage = 1
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadData()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    cancelAndReturn (data, action) {
      this.cancelAndReturnData = data
      this.cancelAndReturnAction = action
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['By choosing "Cancel & Return", you acknowledge that you have communicated with your customer and verified their decision not to proceed with the order. Upon this confirmation, our team will initiate the process to return your order.'],
        accept: this.cancelOrderAction
      })
    },
    cancelOrderAction () {
      this.addAction(this.cancelAndReturnData, this.cancelAndReturnAction)
    },
    addAction (data, action) {
      sendRequest(false, false, this, `api/v1/analytics/merchant/action-center/${data.id}/`, 'post', {action}, true,
        (response) => {
          this.$vs.notify({
            color:'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.loadData()
        }
      )
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadDataSearch)
    },
    loadDataSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadData()
    },
    loadData () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&latest_delivery_attempt_comment=${this.filterValue === 'all' ? '' : this.filterValue}`
      sendRequest(false, false, this, `api/v1/analytics/merchant/action-center/${this.$route.query.tab}/${query}`, 'get', null, true,
        (response) => {
          this.ordersData = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
          this.showDeliverAgainMessage = localStorage.getItem('showDeliverAgainMessage') === 'true'
        }
      )
      this.searchInProgress = false
    }
  },
  created () {
    this.loadData()
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    TableFilter,
    HeadComponent,
    ActionCenterModal,
    RezoningModal
  }
}
</script>