<template>
  <shipblu-prompt
    class="shipment-modal payment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="paymentModalFlag"
    :title="$t('Payment Method')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="text-center">
      <p class="text-darkgray text-base mb-4">{{$t('Select your payment method.')}}</p>
      <div class="flex sm:mt-0 mt-6 items-center justify-center">
        <div v-for="(method, index) in paymentTypes" :key="index">
          <label @click="checkedItem(method, paymentTypes)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
            <input v-model="payment.pay_using" :class="payment.pay_using === method ? 'checked' : ''" :value="method" type="radio" />
            <span class="py-2 px-4 rounded text-sm">{{ method.name }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-6">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button :disabled="Object.keys(payment).length === 0" :class="Object.keys(payment).length === 0 ? 'disable-btn pointer-events-none' : 'active-btn'" class="btn vs-con-loading__container" id="pay-btn-loading" @click="paySupply()">{{ $t('Pay') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendRequest } from '../../../http/axios/requestHelper'
import common from '../../../assets/utils/common'

export default {
  props: ['paymentModalFlag', 'payableID'],
  data () {
    return {
      payment: {},
      paymentTypes: [
        {
          name: 'ShipBlu Balance',
          value: 'cash',
          checked: false
        },
        {
          name: 'Credit Card',
          value: 'card',
          checked: false
        }
      ],
      userMerchant: {}
    }
  },
  methods: {
    checkedItem (type) {
      this.paymentTypes.forEach(item => {
        if (item.name === type.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    closeModal () {
      this.$emit('paymentModalFlag', false)
      this.paymentTypes.forEach(item => { item.checked = false })
      this.payment = {}
    },
    paySupply () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: '#pay-btn-loading',
            scale: 0.45
          })
          let payment = {
            payment_request: this.payableID
          } 
          if (this.payment.pay_using.value === 'card') {
            payment = {
              ...payment,
              billing_data: {
                name: `${this.userMerchant.first_name} ${this.userMerchant.last_name}`,
                email: this.userMerchant.email,
                phone_number: `+2${this.userMerchant.phone_number}`
              },
              pay_using: this.payment.pay_using.value
            }
          }          
          sendRequest(true, false, this, `api/v1/payments/${this.payment.pay_using.value === 'card' ? 'xpay' : 'shipblu-cash'}/pay/`, 'post', payment, true,
            async (response) => {
              if (this.payment.pay_using.value === 'card') {
                const routeData = this.$router.resolve({
                  name: 'merchant-supplies-payment',
                  params: {
                    'transactionID': response.data.id
                  }
                })
                await window.location.assign(routeData.href, '_blank')
              } else {
                this.$emit('loadSupplies')
                this.$vs.notify({
                  color: response.data.status === 'failed' ? 'danger' : 'success',
                  title: response.data.status.toUpperCase(),
                  text: response.data.message,
                  position: 'top-center'
                })
              }
              this.$emit('paymentModalFlag', false)
              this.$vs.loading.close('#pay-btn-loading > .con-vs-loading')
              this.closeModal()
            }, (error) => {
              common.notifyResponseError(this, error)
              this.$vs.loading.close('#pay-btn-loading > .con-vs-loading')
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.userMerchant = results.userMerchantData
    })
  }
}
</script>