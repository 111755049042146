<template>
  <div>
    <div @click="filterDateModal = false" v-if="filterDateModal === true"  class="fixed inset-0 bg-transparent filter-z-index"></div>
    <div class="relative lg:max-w-sm" style="min-width: 100px;"  :class="filterDropdownFlag ? 'filter-z-index' : ''">
      <div
        @click="openFilterDateModal()"
        class="flex h-btn items-center justify-between gap-2 cursor-pointer bg-white border-grey border border-solid rounded p-2 whitespace-nowrap overflow-hidden"
      >
        <div class="flex gap-2 items-center">
          <span class="material-symbols-outlined text-lg">calendar_today</span>
          <p v-if="!startDate" class="truncate w-full capitalize font-medium text-sm">{{ filterLabel && filterLabel.length > 0 ? $t(filterLabel) : $t('All Selected') }}</p>
          <p v-else class="truncate w-full capitalize font-medium text-sm">{{ startDate ? common.gettingDate(startDate).split(',')[1] : ''}} - {{ endDate ? common.gettingDate(endDate).split(',')[1] : '' }}</p>
          </div>
        <div class="text-right flex items-center">
          <feather-icon
            class="w-4 h-4"
            :icon="filterDateModal ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          ></feather-icon>
        </div>
      </div>
      <!-- FILTER DATE MODAL START -->
      <div v-if="filterDateModal" class="calender rounded pt-6 p-4">
        <p class="text-center text-primary text-lg font-medium mb-5">
          {{ $t('Select orders criteria you want to search!') }}
        </p>
        <div class="clearfix flex justify-center">
          <div v-for="(criteria, index) in criterias" :key="index">
            <label @click="checkCriteria(criteria)" class="prompt cursor-pointer whitespace-nowrap">
              <input v-model="criteriaName" :class="criteria.checked ? 'checked' : ''" :value="criteria.value" type="radio" name="select" />
              <span class="text-center py-2 px-4 rounded mx-1">{{ criteria.name }}</span>
            </label>
          </div>
        </div>
        <p class="text-center text-primary text-lg font-medium my-5">
          {{ $t('Select your search range!') }}
        </p>
        <div class="grid grid-cols-2 gap-4">
          <div class="md:col-span-1 col-span-2 flex justify-center">
            <datepicker
              :inline="true"
              name="from"
              :disabledDates="disabledDatesStart"
              v-model="startDate"
            ></datepicker>
          </div>
          <div class="md:col-span-1 col-span-2 flex justify-center">
            <datepicker
              :inline="true"
              name="to"
              :disabledDates="disabledDates"
              v-model="endDate"
            ></datepicker>
          </div>
        </div>
        <div class="flex justify-evenly mt-3">
          <p class="text-blue-900 date-border p-2">
            {{ startDate ? common.gettingDate(startDate) : $t('No Date') }}
          </p>
          <div class="flex" :style="$i18n.locale === 'ar' ? 'transform: rotate(180deg);' : ''">
            <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="Arrow">
            <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="Arrow">
          </div>
          <p class="text-blue-900 date-border p-2">
            {{ endDate ? common.gettingDate(endDate) : $t('No Date') }}
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeFilterDateModal" class="btn disable-btn">
            {{ $t('Clear') }}
          </button>
          <button
            @click="searchByDate"
            :class="{ 'active-btn': startDate && criteriaName, 'disable-btn pointer-events-none': !startDate || !criteriaName }"
            class="btn"
          >
            {{ $t('Search') }}
          </button>
        </div>
      </div>
      <!-- FILTER DATE MODAL END -->
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import common from '../../../assets/utils/common'

export default {
  props: ['filterLabel'],
  data () {
    return {
      common,
      disabledDates: {
        from: new Date()
      },
      disabledDatesStart: {
        from: new Date() 
      },
      startDate: '',
      endDate: '', 
      criterias: [
        {
          name: 'Created',
          value: 'created',
          checked: false
        },
        {
          name: 'Picked up',
          value: 'pickup_date',
          checked: false
        }
      ],
      filterDropdownFlag: false,
      filterDateModal: false,
      criteriaName: '',
      hasExpadableData: false
    }
  },
  methods: {
    openFilterDateModal () {
      this.filterDateModal = !this.filterDateModal
    },
    closeFilterDateModal () {
      this.$nextTick(() => {
        this.criteriaName = ''
        this.startDate = ''
        this.endDate = ''
        this.$emit('searchDate', this.startDate, this.endDate)
        this.filterDateModal = false 
        this.criterias.forEach(criteria => {
          this.$set(criteria, 'checked', false)
        })
      })
    },
    resetDate () {
      this.$nextTick(() => {
        this.criteriaName = ''
        this.startDate = ''
        this.endDate = ''
        this.criterias.forEach(criteria => {
          this.$set(criteria, 'checked', false)
        })
      })
    },
    searchByDate () {
      this.$emit('searchDate', this.startDate, this.endDate, this.criteriaName)
      this.filterDateModal = false
    },
    checkCriteria (criteria) {
      this.criterias.forEach(item => {
        if (item.name === criteria.name) {
          item.checked = true
          this.criteriaName = criteria.value
        } else {
          item.checked = false
        }
      })
    }
  },
  watch: {
    'filterDateModal' (val) {
      if (val === false && this.startDate === '' && this.endDate === '' && this.criteriaName === '') {
        this.$nextTick(() => {
          this.criterias.forEach(criteria => {
            this.$set(criteria, 'checked', false)
          })
        })
      }
      this.disabledDatesStart = {
        from: new Date()
      }
    },
    criteriaName (val) {
      if (val.length === 0) this.resetDate()
    },
    'startDate' () {
      const selected = this.criterias.some(item => item.checked === true)
      if (selected !== true) {
        this.criteriaName = 'pickup_date'
        this.criterias.forEach(item => {
          if (item.name === 'Picked up') {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      }
      const date = new Date(this.startDate)
      this.endDate = this.startDate ? new Date(this.startDate) : ''
      this.disabledDates.to = date
    }
  },
  created () {
    if (this.$route.name.includes('returns-view') || this.$route.name.includes('head-of-fleet-returns') || this.$route.query.tab === 'Returns') {
      this.criterias.push({
        name: 'Returned',
        value: 'returned_date',
        checked: false
      })
    } else if (this.$route.path.includes('orders/delivery') || this.$route.name.includes('merchant-view') || this.$route.name.includes('head-of-fleet-orders') || this.$route.query.tab === 'Delivery Orders') {
      this.criterias.push({
        name: 'Delivered',
        value: 'delivered_date',
        checked: false
      })
    }
  },
  components:{
    Datepicker
  }
}
</script>

<style scoped>
.filter-z-index {
  z-index: 9999;
  user-select: none;
}
.calender {
  width: 642px;
  position: absolute;
  top: 115%;
  left: 0%;
  z-index: 9999 !important;
  background: #ffffff;
  border: 1px solid #BFBFBF;
}
.date-border {
  border-bottom: 0.6px solid #9dadc2;
}
label.prompt input {
    display: none;
  }
label.prompt span {
  background: #F1F3F6;
  border-radius: 4px;
  border: 0.6px solid #F1F3F6;
}
[dir=ltr] label.prompt span {
  float: left;
}
[dir=rtl] label.prompt span {
  float: right;
}
input[type=checkbox] + label.prompt{
  color: #6C84A3;
}
.checked + span{
  background: #FFFFFF !important;
  border: 0.6px solid #1C5BFE !important;
  color: #1C5BFE !important;
  font-weight: 500 !important;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28) !important;
  border-radius: 4px !important;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
input[type=checkbox]:checked + label{
  background: #FFFFFF;
  border: 0.6px solid #1C5BFE;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
}
.btn {
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  line-height: 18px;
} 
.disable-btn {
  background: #F1F3F6;
  color: #6C84A3;
}
.active-btn {
  color: #fff;
  font-weight: 500;
  background: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
}
@media (max-width: 991px) {
  .calender {
    right: -5%;
    left: unset;
    width: 90vw;
  }
}
</style>
