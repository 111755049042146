<template>
  <div>
    <vs-tabs v-model="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
      <vs-tab icon-pack="feather" icon="icon-home" :label="!isSmallerScreen ? $t('Merchant Info') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-info />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-home" :label="!isSmallerScreen ? $t('Pickup Points') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-pickup-points :merchant="merchant"/>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-home" :label="!isSmallerScreen ? $t('Return Points') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-return-points :merchant="merchant"/>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-link" :label="!isSmallerScreen ? $t('Linked Accounts') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-linked-accounts/>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-dollar-sign" :label="!isSmallerScreen ? $t('Pricing') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-pricing-lookups/>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? $t('Deliveries') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-orders />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-box" :label="!isSmallerScreen ? $t('Returns') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-returns />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-clipboard" :label="!isSmallerScreen ? $t('Invoices') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-invoices />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-printer" :label="!isSmallerScreen ? $t('Statements') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-statements />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-briefcase" :label="!isSmallerScreen ? $t('Instant Payouts') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <instant-payouts />
        </div>
      </vs-tab>
      <vs-tab v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales' && $store.state.AppActiveUser.userRole !== 'commercial-admin'" icon-pack="feather" icon="icon-percent" :label="!isSmallerScreen ? $t('Discounts') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-discounts />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? $t('Bank Deposits') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-bank-deposits />
        </div>
      </vs-tab>
      <vs-tab v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'finance'" icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? $t('Payout Methods') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-payout-methods />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-dollar-sign" :label="!isSmallerScreen ? $t('Tax Profile') : ''">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-tax-profile />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-shield" :label="!isSmallerScreen ? $t('ShipBlu Shield') : ''" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'finance'|| $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales' ||  $store.state.AppActiveUser.userRole === 'commercial-admin'">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <merchant-insurance />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>

</template>


<script>
import MerchantInfo from './MerchantInfo.vue'
import MerchantPricingLookups from './MerchantPricingLookups.vue'
import MerchantCashFees from './MerchantCashFees.vue'
import MerchantLinkedAccounts from './MerchantLinkedAccounts.vue'
import MerchantOrders from './MerchantOrders.vue'
import MerchantReturns from './MerchantReturns.vue'
import MerchantInvoices from './MerchantInvoices.vue'
import MerchantStatements from './MerchantStatements.vue'
import MerchantDiscounts from './MerchantDiscounts.vue'
import MerchantBankDeposits from './MerchantBankDeposits.vue'
import MerchantPickupPoints from './MerchantPickupPoints.vue'
import MerchantReturnPoints from './MerchantReturnPoints.vue'
import MerchantPayoutMethods from './MerchantPayoutMethods.vue'
import MerchantTaxProfile from './MerchantTaxProfile.vue'
import MerchantInsurance from './MerchantInsurance.vue'
import InstantPayouts from '../../finance/InstantPayouts.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'

export default {
  components: {
    MerchantInfo,
    MerchantPricingLookups,
    MerchantCashFees,
    MerchantLinkedAccounts,
    MerchantReturns,
    MerchantOrders,
    MerchantInvoices,
    MerchantStatements,
    MerchantDiscounts,
    MerchantBankDeposits,
    MerchantPickupPoints,
    MerchantPayoutMethods,
    MerchantTaxProfile,
    MerchantInsurance,
    MerchantReturnPoints,
    InstantPayouts
  },
  data () {
    return {
      activeTab: '',
      activeTabNameDic: {'info': 0, 'pickup-points': 1, 'return-points': 2, 'linked-accounts': 3, 'pricing': 4, 'orders': 5, 'returns': 6, 'invoices': 7, 'statements': 8, 'instant-payouts': 9, 'discounts': 10, 'bank-deposits': 11, 'payout-methods': 12, 'tax-profile': 13, 'shipblu-shield': 14},
      activeTabDic: {0: 'info', 1: 'pickup-points', 2: 'return-points', 3: 'linked-accounts', 4: 'pricing', 5: 'orders', 6: 'returns', 7: 'invoices', 8: 'statements', 9: 'instant-payouts', 10: 'discounts', 11: 'bank-deposits', 12: 'payout-methods', 13: 'tax-profile',  14: 'shipblu-shield'},
      merchant: {}
    }
  },
  watch: {
    '$route.params.tab' () {
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        },
        query: {
          page: 1
        }
      }).catch(() => {})
    },
    'activeTab' () {
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        },
        query: {
          page: this.$route.query.page
        }
      }).catch(() => {})
    }
  },
  methods: {
    loadMerchant () {
      sendRequest(true, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'get', null, true,
        (response) => {
          this.merchant = response.data
        }
      )
    }
  },
  created () {
    this.activeTab = this.activeTabNameDic[this.$route.params.tab]
    this.loadMerchant()
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
