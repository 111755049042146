<template>
  <div>
    <vs-alert class="mt-5" color="success">
      <span class="flex items-center">
        <feather-icon
          icon="InfoIcon"
          svgClasses="h-4 w-4"
          class="mr-2"
        />
        <span>{{$t('All prices include all applicable taxes.')}}</span>
      </span>
    </vs-alert>

    <shipblu-prompt
      class="discounts-modal"
      @close="closeModal"
      :active.sync="discountsModal"
      :title="$t('Volume Discounts')"
      :buttons-hidden="true">
      <div class="discounts-table">
        <table>
          <thead>
            <tr>
              <th>{{$t('For')}}</th>
              <th>{{$t('Discount')}}</th>
              <th>{{$t('Price')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in volumeDiscounts.slice(1)" :key="index">
              <td>{{ $t(item.for) }}</td>
              <td>{{ (item.discount * 100).toLocaleString('en') + '%' }}</td>
              <td>{{ getFormattedPrice(item.price, $i18n.locale) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="mt-2 text-industrial-revolution">{{ $t('All prices are subject to all applicable taxes.') }}</p>
    </shipblu-prompt>

    <div class="mt-6 flex gap-4">
      <div class="pricing w-full font-normal grid grid-col gap-6 border border-solid border-desired-dawn rounded-xl lg:px-6 lg:py-10 p-4">
        <!-- Monthly Volume -->
        <div>
          <p class="mb-4 text-young-night">{{ $t('Insert your expected monthly volume to find out your prices') }}</p>
          <div class="flex items-center gap-3">
            <div class="relative w-52 leading-tight flex justify-between items-center gap-1 border-1.5 border-solid border-primary rounded p-4">
              <label for="volume" class="absolute text-xs px-1 text-industrial-revolution bg-layout" style="top: -8px;">{{ $t('Monthly Volume') }}</label>
              <input v-model="volumePrice" @input="calculateCost()" id="volume" type="text" class="text-young-night w-4/5 border-0 bg-transparent">
              <p class="text-raven-black">{{ $t(' Order') }}</p>
            </div>
            <div class="border-1.5 border-solid border-range-land rounded-full py-3 px-4">
              <p class="text-range-land font-medium leading-none">{{ Math.round(averageDiscount) + '% ' + $t('Average Discount') }}</p>
            </div>
          </div>
          <p class="text-sm mt-2">
            <span class="text-young-night">{{ $t('See all volume') + ' ' }}</span>
            <span @click="discountsModal = true" class="text-primary border-0 border-b border-solid border-primary cursor-pointer">{{ $t('discounts') }}</span>
          </p>
        </div>

        <!-- Order Type -->
        <div>
          <p class="mb-4 text-young-night">{{ $t('Select Order Type') }}</p>
          <div class="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-3">
            <div v-for="(item, index) in orderTypes" :key="index" class="h-full">
              <input v-model="typePrice" type="radio" :value="item" name="order type" :id="`type_${index}`">
              <label class="block text-sm h-full px-4 py-3" :for="`type_${index}`">
                <div class="flex items-center justify-between gap-2">
                  <p>{{ $t(item.name) }}</p>
                  <span class="icon material-symbols-rounded text-primary text-base">check</span>
                </div>
                <p class="mt-3 text-young-night">{{ getFormattedPrice(item.price, $i18n.locale) }}</p>
              </label>
            </div>
          </div>
        </div>

        <!-- Number Of Packages -->
        <div>
          <p class="mb-4">
            <span class="text-young-night">{{ $t('Select number of packages') + ' ' }}</span>
            <span class="text-industrial-revolution">{{ '(' + $t('You may ship up to 4 packages per order, each will be priced according to its size') + ')' }}</span>
          </p>
          <div class="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-3">
            <div v-for="(item, index) in packages" :key="index" class="h-full">
              <input :disabled="cashCollectionSelected" v-model="packagesPrice[index]" @click="selectPackages(index)" type="radio" :value="item" :name="`package_${index}`" :id="`package_${index}`">
              <label class="block text-sm h-full px-4 py-3" :for="`package_${index}`">
                <div class="flex items-center justify-between gap-2">
                  <p>{{ $t(item.name) }}</p>
                  <span class="icon material-symbols-rounded text-primary text-base">check</span>
                </div>
                <p class="mt-3 text-young-night">{{ getFormattedPrice(item.price, $i18n.locale) }}</p>
              </label>
            </div>
          </div>
        </div>

        <!-- Package Sizes -->
        <div>
          <p class="flex items-center justify-between gap-1 mb-4">
            <span class="text-young-night">{{ $t('Select the size of each of your order packages') }}</span>
            <span v-if="numberOfPackages() < packagesPrice.length && !cashCollectionSelected" class="text-fluorescent-red">{{ numberOfPackages() + $t('/') + packagesPrice.length + ' ' + $t('package sizes are selected') }}</span>
          </p>
          <div class="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-3">
            <div v-for="(item, index) in packagesSizes" :key="index" class="h-full">
              <div :class="`border-1.5 border-solid ${item.count > 0 ? 'border-primary' : 'border-silver'} rounded-lg flex justify-between`">
                <div class="text-sm text-young-night px-4 py-3">
                  <p>
                    <span :class="item.count > 0 && 'text-primary'">{{ $t(item.name) }}</span>
                    <span v-if="item.count > 0" class="text-primary inline">{{ ' (' + item.count + ')' }}</span>
                  </p>
                  <p class="mt-3">{{ getFormattedPrice(item.price, $i18n.locale) }}</p>
                </div>
                <div class="grid grid-cols-1 border-0 border-l border-solid border-silver">
                  <button :disabled="numberOfPackages() >= packagesPrice.length || cashCollectionSelected" @click="changePackageCount(index, 'increase')" class="bg-transparent px-3 outline-none border-0 border-b border-solid border-silver cursor-pointer flex items-center justify-center">
                    <span :class="`material-symbols-outlined text-xl text-primary ${(numberOfPackages() >= packagesPrice.length || cashCollectionSelected) ? 'text-silver' : 'text-primary'}`">add</span>
                  </button>
                  <button :disabled="numberOfPackages() <= 1 || item.count === 0 || cashCollectionSelected" @click="changePackageCount(index, 'decrease')" class="bg-transparent px-3 outline-none border-0 cursor-pointer flex items-center justify-center">
                    <span :class="`material-symbols-outlined text-xl ${(numberOfPackages() <= 1 || item.count === 0 || cashCollectionSelected) ? 'text-silver' : 'text-primary'}`">remove</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Destination -->
        <div>
          <p class="mb-4">
            <span class="text-young-night">{{ $t('Select destination') + ' ' }}</span>
            <span class="text-industrial-revolution">{{ '(' + $t('Destination prices are based on pickup locations in Cairo and Giza') + ')' }}</span>
          </p>
          <div class="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-3">
            <div v-for="(item, index) in destinations" :key="index" class="h-full">
              <input v-model="destinationPrice" type="radio" :value="item" name="destination" :id="`destination_${index}`">
              <label class="block text-sm h-full px-4 py-3" :for="`destination_${index}`">
                <div class="flex items-center justify-between gap-2">
                  <p>{{ $t(item.name) }}</p>
                  <span class="icon material-symbols-rounded text-primary text-base">check</span>
                </div>
                <p class="mt-3 text-young-night">{{ getFormattedPrice(item.price, $i18n.locale) }}</p>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="md:relative fixed bottom-0 left-0 xl:w-80 lg:w-76 md:w-72 bg-primary flex-shrink-0 rounded-xl lg:py-10 py-4">
        <div class="lg:px-6 px-4">
          <div class="text-center">
            <p class="text-white text-2xl font-bold">{{ $t('SingleBlu Plan') }}</p>
            <p class="mt-3 xl:w-4/5 m-auto text-eyeshadow">{{ $t('Your average shipping fees based on your monthly volume') }}</p>
            <p class="mt-2 font-semibold text-white">
              <span class="text-5xl">{{ getFormattedPrice(typePrice.price + destinationPrice.price + selectedFlyerSizes.reduce((sum, { price }) => sum + price, 0) + packagesPrice.reduce((sum, { price }) => sum + price, 0)) + ' '}}</span>
              <span class="text-lg">{{ $t('EGP') + ' / ' + $t('order') }}</span>
            </p>
          </div>
          <div class="mt-6 flex flex-col gap-5 text-stoic-white">
            <div v-for="(item, index) in packagesPrice" :key="index">
              <div class="mb-2 flex items-center gap-3">
                <p class="flex-shrink-0 text-xs">{{ $t(item.name) }}</p>
                <div class="w-full border-0 border-b border-solid border-c64-ntsc"></div>
              </div>
              <div class="leading-tight flex justify-between items-center">
                <p class="text-sm">{{ $t('Base Price') }}</p>
                <p class="text-xs">{{ getFormattedPrice(packages[index].price) }}</p>
              </div>
              <div class="flex justify-between items-center">
                <p class="text-sm">{{ $t(`Package Size ${selectedFlyerSizes[index].name}`) }}</p>
                <p class="text-xs">{{ getFormattedPrice(selectedFlyerSizes[index].price) }}</p>
              </div>
            </div>
            <div>
              <div class="mb-2 flex items-center gap-3">
                <p class="flex-shrink-0 text-xs">{{ $t('Destination') }}</p>
                <div class="w-full border-0 border-b border-solid border-c64-ntsc"></div>
              </div>
              <div class="leading-tight flex justify-between items-center">
                <p class="text-sm">{{ $t(destinationPrice.name) }}</p>
                <p class="text-xs">{{ getFormattedPrice(destinationPrice.price) }}</p>
              </div>
            </div>
          </div>
          <p class="mt-8 text-white">{{ $t('All prices are subject to all applicable taxes.') }}</p>
        </div>
        <div class="md:absolute md:block hidden bottom-0 py-9 w-11/12" style="left: 0;">
          <img src="../../../assets/images/pages/lined-logo.svg" class="w-full" alt="logo">
        </div>
      </div>
    </div>

    <div class="mt-4 flex items-center gap-3 border border-solid border-desired-dawn rounded-xl px-6 py-5">
      <img class="w-6 h-6" src="@/assets/images/pages/light-2.svg" alt="light-icon">
      <p class="text-young-night">{{ $t('EGP 2.99 surcharge fee applies to these zones: Helwan, Future City, New Heliopolis City, Badr City, Madinaty, 10th of Ramadan, El Shourouk, and Obour.') }}</p>
    </div>

    <div class="mt-4 flex items-center gap-3 border border-solid border-desired-dawn rounded-xl px-6 py-5">
      <img class="w-6 h-6" src="@/assets/images/pages/light-2.svg" alt="light-icon">
      <p class="text-young-night">{{ $t('0.5% fee will be applied to to any COD collected or refunds processed with a minimum EGP 1 per order.') }}</p>
    </div>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  data () {
    return {
      averageDiscount: 0,
      averageShippingFee: 0,
      volumePrice: 500,
      discountsModal: false,
      typePrice: {},
      destinationPrice: {},
      packagesPrice: [],
      selectedFlyerSizes: [],
      volumeDiscounts: [
        {
          for: '0',
          discount: 0,
          price: 67.85,
          threshold: 0
        },
        {
          for: 'First 100 pkgs',
          discount: 0,
          price: 67.85,
          threshold: 100
        },
        {
          for: 'Next 50 pkgs',
          discount: 0.15,
          price: 57.67,
          threshold: 150
        },
        {
          for: 'Next 50 pkgs',
          discount: 0.25,
          price: 50.89,
          threshold: 200
        },
        {
          for: 'Next 2,300 pkgs',
          discount: 0.30,
          price: 47.50,
          threshold: 2500
        },
        {
          for: 'Next 2,500 pkgs',
          discount: 0.35,
          price: 44.10,
          threshold: 5000
        },
        {
          for: 'Next 5,000 pkgs',
          discount: 0.37,
          price: 42.75,
          threshold: 10000
        },
        {
          for: 'Next 4,000 pkgs',
          discount: 0.40,
          price: 40.71,
          threshold: 14000
        },
        {
          for: 'Next 3,000 pkgs',
          discount: 0.50,
          price: 67.85,
          threshold: 17000
        },
        {
          for: 'Next pkgs',
          discount: 0.3844,
          price: 41.77,
          threshold: 1000000
        }
      ],
      orderTypes: [],
      packages: [],
      packagesSizes: [],
      destinations: [],
      cashCollectionSelected: false
    }
  },
  watch: {
    'packagesPrice.length' (val) {
      if (val < this.numberOfPackages()) {
        this.packagesSizes.forEach((item, index) => { item.count = index === 0 ? 1 : 0 })
      }
      this.updatePackagesInCart()
    },
    typePrice (val) {
      if (val.name === ' Cash Collection') {
        this.cashCollectionSelected = true
        this.packagesPrice = [this.packages[0]]
        this.packagesSizes.forEach((item) => { item.count = 0 })
      } else {
        this.cashCollectionSelected = false
        this.packagesSizes[0].count = 1
      }
    }
  },
  methods: {
    numberOfPackages () {
      return this.packagesSizes.reduce((sum, { count }) => sum + count, 0)
    },
    changePackageCount (packageIndex, type) {
      if (type === 'increase') {
        this.packagesSizes[packageIndex].count += 1
      } else {
        this.packagesSizes[packageIndex].count -= 1
      }
      this.packagesSizes = [...this.packagesSizes]
      this.updatePackagesInCart()
    },
    selectPackages (packageIndex) {
      this.packagesPrice = this.packages.slice(0, packageIndex + 1)
    },
    getFormattedPrice (price, locale) {
      return locale === 'en' ? `${this.$t('EGP')} ${price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}` : locale === 'ar' ? `${price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})} ${this.$t('EGP')}` : price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})
    },
    closeModal () {
      this.discountsModal = false
    },
    updatePackagesInCart () {
      this.selectedFlyerSizes = []
      this.packagesSizes.forEach((item) => {
        for (let i = 0; i < item.count; i++) {
          this.selectedFlyerSizes.push(item)
        }
      })
      this.packagesPrice.forEach(() => {
        if (this.selectedFlyerSizes.length < this.packagesPrice.length) {
          this.selectedFlyerSizes.push(this.packagesSizes[0])
        }
      })
      this.selectedFlyerSizes = this.selectedFlyerSizes.sort((a, b) => a.id - b.id)
    },

    // Calculate the cost based on volume thresholds
    calculateCost () {
      let totalCost = 0
      const basePrice = 67.85
      if (this.volumePrice >= this.volumeDiscounts[this.volumeDiscounts.length - 1].threshold) {
        this.averageShippingFee = 41.77
        this.averageDiscount = (basePrice - this.averageShippingFee) * 100 / basePrice
      } else {
        for (let i = 0; i < this.volumeDiscounts.length; i++) {
          if (this.volumePrice >= this.volumeDiscounts[i].threshold) {
            const lastThreshold = i > 0 ? this.volumeDiscounts[i - 1].threshold : 0
            totalCost += (this.volumeDiscounts[i].threshold - lastThreshold) * basePrice * (1 - this.volumeDiscounts[i].discount)
          } else {
            totalCost += (this.volumePrice - this.volumeDiscounts[i - 1].threshold) * basePrice * (1 - this.volumeDiscounts[i].discount)
            break
          }
        }
        this.averageShippingFee = totalCost / this.volumePrice
        this.averageDiscount = (basePrice - this.averageShippingFee) * 100 / basePrice
      }
      this.applyAdditions()
    },
    applyAdditions () {
      this.initializeAdditionalValues()
      this.orderTypes.forEach(item => { item.price = item.price * this.averageShippingFee })
      this.packages.forEach(item => { item.price = item.price * this.averageShippingFee })
      this.packagesSizes.forEach(item => { item.price = item.price * this.averageShippingFee })
      this.destinations.forEach(item => { item.price = item.price * (1 - (this.averageDiscount / 100)) })
    },
    initializeAdditionalValues () {
      this.orderTypes = [
        {
          name: 'Delivery or Return',
          price: 1
        },
        {
          name: ' Exchange',
          price: 2
        },
        {
          name: ' Cash Collection',
          price: 0.5
        },
        {
          name: 'RTO',
          price: 0.9
        }
      ]
      this.packages = [
        {
          name: '1st Package',
          price: 0
        },
        {
          name: '2nd Package',
          price: 0.25
        },
        {
          name: '3rd Package',
          price: 0.2
        },
        {
          name: '4th Package',
          price: 0.15
        }
      ]
      this.packagesSizes = [
        {
          id: 4,
          name: 'Small',
          price: 0,
          count: 1
        },
        {
          id: 3,
          name: 'Medium',
          price: 0,
          count: 0
        },
        {
          id: 2,
          name: 'Large',
          price: 0.1,
          count: 0
        },
        {
          id: 1,
          name: 'X-Large',
          price: 0.15,
          count: 0
        }
      ]
      this.destinations = [
        {
          name: 'Cairo & Giza',
          price: 0
        },
        {
          name: 'Alexandria',
          price: 6.9
        },
        {
          name: 'Delta & Canal Regions',
          price: 9.2
        },
        {
          name: 'Assiut',
          price: 60
        },
        {
          name: 'North Cost',
          price: 70
        }
      ]
      this.typePrice = this.orderTypes[0]
      this.packagesPrice = [this.packages[0]]
      this.destinationPrice = this.destinations[0]
      this.selectedFlyerSizes = [this.packagesSizes[0]]
    }
  },
  components: {
    ShipbluPrompt
  },
  created () {
    this.calculateCost()
  }
}
</script>

<style lang="scss">
.pricing {
  input[type=radio], input[type=checkbox] {
    display: none;
  }
  input[type=radio] + label, input[type=checkbox] + label {
    cursor: pointer;
    user-select: none;
    border: 1.5px solid #BFBFBF;
    color: #565656;
    border-radius: 8px;
    .icon {
      display: none;
    }
  }
  input[type=radio]:checked + label, input[type=checkbox]:checked + label {
    border: 1.5px solid #1C5BFE;
    color: #1C5BFE;
    background-color: transparent;
    box-shadow: none;
    .icon {
      display: block;
    }
  }
}
div.discounts-modal {
  .vs-dialog{
    max-width: 560px !important;
  }
  .discounts-table {
    overflow-x: auto;
    border: 1px solid #DCDCDC;
    border-radius: 6px;
    table {
      border-collapse: collapse;
      width: 100%;
      tr {
        &:first-child td {
          padding-top: 12px;
        }
        &:last-child td {
          padding-bottom: 12px;
        }
      }
      th {
        border-bottom: 1px solid #DFDFDF;
        padding: 14px 28px;
      }
      td {
        padding: 4px 28px;
      }
      th, td {
        &:not(:last-child) {
          border-right: 1px solid #DFDFDF;
        }
        white-space: nowrap;
        text-align: left;
        color: #565656;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
</style>