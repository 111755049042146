<template>
  <div class="flex flex-col justify-between" style="min-height: calc(100dvh - 188px);">
    <div>
      <p class="text-black font-medium md:text-2xl text-xl md:mb-8 mb-5">{{ $t('New Module') }}</p>
      <div>
        <p class="font-medium md:text-lg text-black">{{ 'Module Name' }}</p>
        <p v-if="editable === false" class="md:mt-4 mt-2 text-grey-dark">{{ module.name }}</p>
        <div v-else class="md:mt-4 mt-2">
          <input v-model="module.name" autocomplete="nofill" class="md:w-1/2 w-full input-order" v-validate="'required'" name="module name" />
          <p class="text-danger text-sm" v-show="errors.has('module name')">{{ errors.first('module name') }}</p>
        </div>
      </div>
      <p class="font-medium text-lg text-black mt-16">{{ 'Module Material' }}</p>
      <div class="md:w-1/2 w-full border border-grey-light border-dashed mt-4 rounded-xl p-16">
        <div>
          <input type="file" class="hidden" ref="fileInput" accept=".pdf,video/mp4,video/x-m4v,video/*" @change="handleClick">
          <div
            @click="$refs.fileInput.click()"
            @drop="handleDrop"
            @dragover="handleDragover"
            @dragenter="handleDragover"
            class="cursor-pointer text-base text-center">
            <div class="pb-2 text-sm font-semibold">
              <div class="flex items-center gap-2 justify-center">
                <p>
                  <span class="font-medium text-xl text-black">{{$t('Drop PDF, Video or') + ' '}}</span>
                  <span class="text-primary text-xl font-medium" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
                </p>
              </div>
              <p class="text-primary text-xl font-medium">{{ uploadedFile.title ? uploadedFile.title : '' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editable === true" class="flex gap-4 mt-8">
      <vs-button @click="createModule()" class="w-32">{{ 'Create' }}</vs-button>
      <vs-button class="w-32 bg-grey" @click="$router.push(`/${$route.params.lang}/ops-admin/agents/training-program?tab=content&programId=${$route.query.programId}`).catch(() => {})">{{ 'Cancel' }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18nData from '../../../i18n/i18nData'
import { sendRequest } from '../../../http/axios/requestHelper'
export default {
  data () {
    return {
      originFile: {},
      uploadedFile: {},
      module: {},
      editable: true 
    }
  },
  methods: {
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isVideoAndPdf(rawFile)) {
        this.$vs.notify({
          title: 'Incorrect file',
          text: 'Only supports upload .pdf, video files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
      this.originFile = rawFile
      this.uploadFile(rawFile)
    },
    isVideoAndPdf (file) {
      return /\.(avi|AVI|wmv|WMV|flv|FLV|mpg|MPG|mp4|MP4|pdf|PDF|webm|WEBM)$/.test(file.name)
    },
    uploadFile (file) {
      if (!this.isVideoAndPdf(file)) {
        this.$vs.notify({
          color: 'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: 'Only supports upload .pdf, video files',
          position: 'top-center'
        })
        return false
      }
      this.$refs['fileInput'].value = null // fix can't select the same excel
      const formData = new FormData()
      formData.append('file', file)
      if (!this.isOnline) this.uploadInvoiceModal = false
      sendRequest(false, false, this, 'api/v1/upload-file/', 'post', formData, true,
        (response) => {
          this.uploadedFile = {
            title: file.name,
            file_key: response.data.file_url
          }
        }
      )
    },
    createModule () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const moduleObj = {
            name: this.module.name,
            chapter: this.$route.query.chapterId,
            files: [this.uploadedFile]
          }
          sendRequest(true, false, this, 'api/v1/training/modules/', 'post', moduleObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Module'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.loadModule()
            }
          )
        }
      })
    },
    loadModule () {
      sendRequest(true, false, this, 'api/v1/training/modules/', 'get', null, true,
        (response) => {
          const modules = response.data.results.filter(item => item.chapter === Number(this.$route.query.chapterId))
          if (modules.length > 0) {
            this.module = modules[0]
            this.editable = false
          }
          this.uploadedFile = {
            title : this.module.files[0].title,
            file_key : this.module.files[0].file_key
          }
        }
      )
    }
  },
  created () {
    this.loadModule()
  }
}
</script>
<style lang="scss">
.input-order {
  border: solid 1px #CDCDCD;
  padding: 8px;
  border-radius: 4px;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: #9DADC2 !important;
  }
}
</style>