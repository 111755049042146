<template>
  <div class="relative">
    <div class="md:absolute relative mb-2 md:mb-0" style="top: 20px;">
      <div @click="filterDropdownFlag = false" v-if="filterDropdownFlag === true" class="fixed inset-0 bg-transparent filter-z-index"></div>
      <div class="relative md:w-48 w-full" :class="filterDropdownFlag ? 'filter-z-index' : ''">
        <div @click="filterDropdownFlag = !filterDropdownFlag" class="flex gap-4 cursor-pointer bg-white items-center border border-solid border-grey-light p-2 rounded-lg whitespace-no-wrap overflow-hidden">
          <span class="material-icons-outlined text-lg">equalizer</span>
          <p class="truncate w-full capitalize font-medium text-sm">{{filters.length === 0 ? $t('Status') : filters.map(item => $t(item.replaceAll('_', ' '))).join(', ')}}</p>
          <div class="text-right flex items-center">
            <feather-icon class="w-4 h-4" :icon="filterDropdownFlag ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
          </div>
        </div>
        <div v-if="filterDropdownFlag" class="absolute w-full left-0 bg-white shadow-md p-2 rounded-lg " style="top: 40px;">
          <div @click="changeFilter(item.value)" v-for="item in statusFilters" :key="item.index">
            <vs-checkbox class="mb-2 " :checked="filters.length === 0 && item.value === 'all' ? true : filters.includes(item.value)" :vs-value="item.value">
              <span class="capitalize">{{ $t(item.name) }}</span>
            </vs-checkbox>
          </div>
        </div>
      </div>
    </div>
    <ship-blu-table    
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="tableData"
      :tableLoader="tableLoader">

      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('HR ID')}}</shipblu-th>
        <shipblu-th>{{$t('Start')}}</shipblu-th>
        <shipblu-th>{{$t('Due')}}</shipblu-th>
        <shipblu-th>{{$t('Completion')}}</shipblu-th>
        <shipblu-th>{{$t('Trails')}}</shipblu-th>
        <shipblu-th>{{$t('Score')}}</shipblu-th>
        <shipblu-th>{{$t('Hub')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].hr_id">
            {{ data[indextr].hr_id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].startDate">
            {{ data[indextr].startDate }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].dueDate">
            {{ data[indextr].dueDate }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].completionDate">
            {{ data[indextr].completionDate }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].trails">
            {{ data[indextr].trails }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].score">
            {{ data[indextr].score }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].hub">
            {{ data[indextr].hub }}
          </shipblu-td>
          
        </shipblu-tr>
      </template>
    </ship-blu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import ShipBluTable from '../../../layouts/components/ShipBluTable.vue'
import TableFilter from '../../merchant/components/TableFilter.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import common  from '@/assets/utils/common'

export default {
  props: ['activeTab', 'tabsDic'],
  data () {
    return {
      tableData: [
        {
          name: 'Ahmed Ali',
          hrID: 52135,
          startDate: new Date().toISOString().split('T')[0],
          dueDate: new Date().toISOString().split('T')[0],
          completionDate: new Date().toISOString().split('T')[0],
          trails: 2,
          score: 50,
          hub: 'Cairo'
        }
      ],
      filterDropdownFlag: false,
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        }
      ],
      filters: [],
      currentPage: 1,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 100,
      offset: 0,
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' ',
      selected: []
    }
  },
  methods: {
    changeFilter (type) {
      this.filterValue = type
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          tab: this.tabsDic[this.activeTab],
          filter: this.filters
        }
      }).catch(() => {})
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCommercialAdminsSearch)
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
    }
  },
  components: {
    TableFilter,
    ShipbluPagination,
    ShipBluTable,
    ShipbluTd,
    ShipbluTr,
    ShipbluTh
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
  }
}
</script>

<style lang="scss">
th {
  height: 50px ;
  vertical-align: center;
  font-size: 14px;
  font-weight: 400;
}
.filter-z-index {
  z-index: 9999;
  user-select: none;
}
.wrapper {
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: 24px;
}
table {
  min-width: 700px;
}
</style>