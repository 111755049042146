<template>
  <div>
    <!-- header -->
    <div v-if="!hideHeader" class="vs-component vs-con-table mb-4 rounded-xl border border-solid border-grey-light bg-white">
      <header class="header-table vs-table--header">
        <div class="flex flex-wrap-reverse lg:flex-row flex-col-reverse w-full gap-2 items-center lg:justify-between md:py-6 p-4">
          <slot name="header"></slot>
          <div v-if="search" class="vs-table--search lg:mr-0 mr-auto">
            <input v-if="$route.name === 'merchant-integrations'" :placeholder="$t('Search by tracking number')" id="searchInput" v-model="searchx" class="input-search vs-table--search-input" type="text">
            <input v-else :placeholder="orders ? $route.name.includes('supplies') ?  $t('Merchant Name') : ($route.name.includes('fulfillment') ? $t('Customer name, SKU ') : $t('Tracking number, ')) + $t('or reference number...') : $t('Search')" id="searchInput" v-model="searchx" class="input-search vs-table--search-input w-full" type="text">
          </div>
        </div>
      </header>
    </div>

    <!-- Responsive Table -->
    <div class="sm:block hidden border border-solid mb-3 rounded-xl p-4 table-responsive-border bg-white w-full" v-if="$route.name === 'merchant-shipments' && isCheckAllInPage()">
      <span>{{(checkAll ? 'All' : selected.length) + ' ' + $t('orders are selected') + ' '}}</span>
      <span class="text-primary cursor-pointer underline" @click="checkAll = !checkAll">{{ !checkAll ? $t('Select all') + ' ' + ' ' + $t('Orders') : $t('Clear selection')}}</span>
    </div>
    <div v-if="multiple" class="sm:hidden flex border border-solid mb-3 rounded-xl p-4 table-responsive-border bg-white">
      <vs-checkbox
        class="flex justify-center"
        :key="isCheckedLine ? 'remove' : 'check'"
        :icon="isCheckedLine ? 'remove' : 'check'"
        :checked="isCheckedMultiple"
        size="small"
        @change="changeCheckedMultiple()"
      />
      <span v-if="(selected.length === data.length || selected.length > 0) && isCheckAllInPage()">
        {{ !checkAll ? selected.length : 'All'}}  {{ $t('orders are selected') }} <span class="text-primary cursor-pointer underline" @click="checkAll = !checkAll" v-if="checkAllInPage"> {{ !checkAll ? $t('Select all') + ' ' + $t('Orders') : $t('Clear selection')}}</span>
      </span>
      <span v-else>
        {{ $t('Click to select all orders in this page') }}
      </span>
    </div>

    <!-- Main Table -->
    <div :class="[{ stripe: stripe, hoverFlat: hoverFlat }, `vs-table-${color}`]">
      <div class="con-tablex vs-table--content sm:border sm:border-solid sm:border-grey-light sm:rounded-lg sm:bg-white">
        <div
          :style="styleConTbody"
          class="vs-con-tbody vs-table--tbody">

          <!-- TableLoader -->
          <table v-if="tableLoader" class="table-loader w-full mt-3 text-center">
            <tr>
              <th v-for="(h, index) in 10" :key="index" class="py-3 lg:px-7 px-3">  
                <div class="line w-full rounded-lg h-4 my-3"></div>
              </th>
            </tr>
            <tr v-for="(r, index) in 5" :key="index">
              <td v-for="(b, index) in 10" :key="index" class="py-3 lg:px-5 px-1">
                <div class="line w-full rounded-lg h-4 my-2"></div>
              </td>
            </tr>
          </table>

          <table v-else
            ref="table"
            class="vs-table vs-table--tbody-table text-center new-shipblu-table">
            <thead
              ref="thead"
              class="vs-table--thead">
              <tr class="header">
                <th v-if="multiple || hasExpadableData">
                  <span
                    v-if="multiple"
                    class="header w-16">
                    <vs-checkbox
                      class="flex justify-center"
                      :key="isCheckedLine ? 'remove' : 'check'"
                      :icon="isCheckedLine ? 'remove' : 'check'"
                      :checked="isCheckedMultiple"
                      size="small"
                      @change="changeCheckedMultiple()"/>
                  </span>
                </th>
                <slot name="thead"></slot>
              </tr>
            </thead>
            <slot :data="datax"></slot>
          </table>
        </div>
        <div
          v-if="isNoData && !tableLoader"
          class="not-data-table vs-table--not-data">
          {{ noDataText }}
        </div>
        <div
          v-if="pagination"
          class="con-pagination-table vs-table--pagination">
          <vs-pagination
            v-model="currentx"
            :total="searchx && !sst ? getTotalPagesSearch : getTotalPages"
            :description-items="descriptionItems"
            :max-items="maxItemsx"
            :size-array="queriedResults.length"
            :description="description"
            :description-title="descriptionTitle"
            :description-connector="descriptionConnector"
            :description-body="descriptionBody"
            @changeMaxItems="changeMaxItems"
          >
          </vs-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>  
/* eslint-disable */
  export default {
    name: "VsTable",
    props:{
      tableLoader: {
        type: Boolean,
        default: false
      },
      orders: {
        type: Boolean,
        default: false
      },
      value:{
        type: Number| Array,
        default: 0,
        required: false
      },
      color: {
        default:'primary',
        type: String
      },
      noDataText: {
        default: 'No data Available',
        type: String
      },
      stripe:{
        default: false,
        type: Boolean
      },
      hoverFlat:{
        default: false,
        type: Boolean
      },
      maxHeight:{
        default: 'auto',
        type: String
      },
      multiple:{
        default: false,
        type: Boolean
      },
      data:{
        default: null,
      },
      notSpacer:{
        default:false,
        type:Boolean
      },
      search:{
        default: false,
        type: Boolean
      },
      searchDate:{
        default: false,
        type: Boolean
      },
      maxItems:{
        default: 5,
        type: [Number, String]
      },
      pagination:{
        default: false,
        type: Boolean
      },
      description:{
        default: false,
        type: Boolean
      },
      descriptionItems:{
        default: () => [],
        type: Array
      },
      descriptionTitle: {
        type:String,
      },
      descriptionConnector: {
        type:String,
      },
      descriptionBody: {
        type:String,
      },
      currentPage: {
        default: 1,
        type: Number | String
      },
      sst:{
        default: false,
        type: Boolean
      },
      total: {
        type: Number,
        default: 0
      },
      onlyClickCheckbox: {
        type: Boolean,
        default: false
      },
      checkAll: {
        type: Boolean,
        default: false
      },
      checkAllInPage:{
        type: Boolean,
        default: false
      },
      selected:{
        default: () => [],
        type: Array
      },
      filters:{
        default: () => [],
        type: Array
      },
      hideHeader: {
        type: Boolean,
        default: false 
      }
    },
    data:()=>({
      printLableStatuses: ['pickup_rescheduled', 'pickup_requested', 'created', 'out_for_pickup'],
      datax: [],
      searchx: null,
      currentx: 1,
      maxItemsx: 5,
      currentSortKey: null,
      currentSortType: null,
      hasExpadableData: false
    }),
    computed:{
      getTotalPages() {
        const totalLength = this.sst && this.total ? this.total : this.data.length
        return Math.ceil(totalLength / this.maxItemsx)
      },
      getTotalPagesSearch() {
        return Math.ceil(this.queriedResults.length / this.maxItems)
      },
      queriedResults() {
        let queriedResults = this.data
        if(this.searchx && this.search) {
          let dataBase = this.data
          queriedResults = dataBase.filter((tr)=>{
            let values = this.getValues(tr).toString().toLowerCase()
            return values.indexOf(this.searchx.toLowerCase()) != -1
          })
        }
        return queriedResults
      },
      isNoData() {
        if(typeof(this.datax) == Object) {
          return this.datax ? Object.keys(this.datax).length == 0:false && this.search
        } else {
          return this.datax?this.datax.length == 0:false && this.search
        }
      },
      isCheckedLine () {
        let lengthx = (this.data !== null && typeof (this.data) === 'object') ? this.data.length : 0
        let lengthSelected = this.value ? this.value.length : 0
        return lengthx !== lengthSelected
      },
      isCheckedMultiple () {
        return this.value.length > 0
      },
      styleConTbody () {
        return {
          maxHeight: this.maxHeight,
          overflow: this.maxHeight != 'auto'?'auto':null
        }
      },
      getThs () {
        let ths = this.$slots.thead.filter(item => item.tag )
        return ths.length
      }
    },
    watch:{
      selected (val) {
        if (val.length !== this.data.length) {
          this.checkAll = false
          this.$emit('checkAll', false)
        }
      },
      isCheckedLine (val) {
        this.$emit('checkAllInPage', val ? false : true)
        !val && this.checkAll === true ? '' : this.$emit('checkAll', false)
      },
      checkAll (val) {
        this.$emit('checkAll', val)
        !val ? this.changeCheckedMultiple() : ''
      },
      '$route.params.warehouseID' (val) {
        this.searchx = null
      },
      currentPage() {
        this.currentx = this.currentPage
      },
      currentx() {
        if(this.sst) {
          this.$emit('change-page', this.currentx)
        } else {
          this.loadData()
        }
      },
      maxItems(val) {
        this.maxItemsx = val
        this.loadData()
      },
      maxItemsx() {
        this.loadData()
      },
      data() {
        this.loadData()
        this.$nextTick(() => {
          if(this.datax.length > 0) {
            this.changeTdsWidth()
          }
        })
      },
      searchx() {
        if(this.sst) {
          this.$emit('search', this.searchx)
        } else {
          this.loadData()
          this.currentx = 1
        }
      }
    },
    mounted () {
      this.maxItemsx = this.maxItems
      this.loadData()
    },
    destroyed () {
    },
    methods:{
      loadData() {
        let max = Math.ceil(this.currentx * this.maxItemsx)
        let min = max - this.maxItemsx
        if(!this.searchx || this.sst) {
          this.datax = this.pagination ? this.getItems(min, max) : this.sortItems(this.data) || [];
        } else {
          this.datax = this.pagination ? this.getItemsSearch(min, max) : this.getItemsSearch(min, max) || []
        }
      },
      getItems(min, max) {
        let dataBase = this.sortItems(this.data);
        let items = []
        dataBase.forEach((item, index) => {
          if(index >= min && index < max) {
            items.push(item)
          }
        })
        return items
      },
      sortItems(data) {
        const { currentSortKey, currentSortType } = this;
        function compare(a,b) {
          if (a[currentSortKey] < b[currentSortKey])
            return currentSortType == 'desc'?1:-1;
          if (a[currentSortKey] > b[currentSortKey])
            return currentSortType == 'desc'?-1:1;
          return 0;
        }
        return currentSortType !== null ? [...data].sort(compare) : [...data];
      },
      getItemsSearch(min, max) {
        const search = this.normalize(this.searchx);
        return this.sortItems(this.data).filter((tr)=>{
          return this.normalize(this.getValues(tr).toString()).indexOf(search) != -1
        }).filter((_, index) => {
          return (index >= min && index < max);
        });
      },
      sort(key, sortType) {
        this.currentSortKey = key;
        this.currentSortType = sortType;
        if(this.sst) {
          this.$emit('sort', key, sortType)
          return
        }
        this.loadData();
      },
      normalize(string) {
        return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
      },
      getValues: function getValues(obj) {
        function flattenDeep(val) {
          return Object.values(val || []).reduce((acc, val) => (typeof val === 'object') ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
        }
        return flattenDeep(obj).filter(function (item) {
          return (typeof item === 'string') || (typeof item === 'number');
        });
      },
      changeCheckedMultiple () {
        let lengthx = this.data.length
        let lengthSelected = this.value.length
        let selectedx = (lengthx - lengthSelected)
        if (selectedx == 0) {
          this.$emit('input', [])
        } else {
          this.$emit('input', this.data)
        }
      },
      handleCheckbox(tr) {
        if(this.multiple && this.onlyClickCheckbox){
          let val = this.value.slice(0)
          if(val.includes(tr)) {
            val.splice(val.indexOf(tr),1)
          } else {
            val.push(tr)
          }
          this.$emit('input', val)
          this.$emit('selected', tr)
        }
      },
      clicktr (tr, isTr) {
        if(this.multiple && isTr && !this.onlyClickCheckbox){
          let val = this.value.slice(0)
          if(val.includes(tr)) {
            val.splice(val.indexOf(tr),1)
          } else {
            val.push(tr)
          }
          this.$emit('input', val)
          this.$emit('selected', tr)
        } else if (isTr && !this.onlyClickCheckbox) {
          this.$emit('input', tr)
          this.$emit('selected', tr)
        }
      },
      dblclicktr (tr, isTr) {
        if (isTr) {
          this.$emit('dblSelection',tr)
        }
      },
      isCheckAllInPage() {
        return this.checkAllInPage && this.printLableStatuses.some(item => this.filters.includes(item)) && this.filters.length === 1
      },
      changeTdsWidth() {
        if(!this.value) return
        let tbody = this.$refs.table ? this.$refs.table.querySelector('tbody') : ''
        // Adding condition removes querySelector none error - if tbody isnot present
        if(tbody) {
          let trvs = tbody.querySelector('.tr-values')
          if (trvs === undefined || trvs === null ) return
          let tds = trvs.querySelectorAll('.td')
          let tdsx = []
          tds.forEach((td, index) => {
            tdsx.push({index: index, widthx: td.offsetWidth})
          });
          let colgrouptable = this.$refs.colgrouptable
          if (colgrouptable !== undefined && colgrouptable !== null ) {
            let colsTable = colgrouptable.querySelectorAll('.col')
            colsTable.forEach((col, index) => {
              col.setAttribute('width', tdsx[index].widthx)
            })
          }
        }
      },
      changeMaxItems (index) {
        this.maxItemsx = this.descriptionItems[index]
      }
    },
    created () {
    this.searchx = this.$route.query.search ? this.$route.query.search : null
    }
  }
  </script>
  
  <style lang="scss">
  .new-td {
    text-align: start !important;
    color: #000;
    width: 1%;
    white-space: nowrap;
  }
  .checked + span{
    background: #FFFFFF !important;
    border: 0.6px solid #1C5BFE !important;
    color: #1C5BFE !important;
    font-weight: 500 !important;
    box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28) !important;
    border-radius: 4px !important;
  }
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  input[type=checkbox]:checked + label{
    background: #FFFFFF;
    border: 0.6px solid #1C5BFE;
    box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
    border-radius: 4px;
  }
  .btn {
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    line-height: 18px;
  } 
  .disable-btn {
    background: #F1F3F6;
    color: #6C84A3;
  }
  .active-btn {
    color: #fff;
    font-weight: 500;
    background: #1C5BFE;
    box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
  }
  .scroll {
    overflow-x: auto;
    visibility: visible;
  }
  table.new-shipblu-table {
    width: 100%;
    border-spacing: 0px 0px;
    th {
      position: relative;
      padding: 14px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      text-align: center !important;
      border-bottom: 1px solid #E5E5E5;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
      input {
        position: absolute;
        top: calc(50% - 6.5px);
      }
    }
    td {
      border-bottom: 1px solid #E5E5E5;
      vertical-align: top;
      .hidden:first-child {
        border: none !important;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      padding: 16px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000;
    }
    .status-icon {
      border-left: 4px solid;
      border-radius: 8px;
    }
  }
  .link {
    cursor: pointer;
    text-decoration: underline;
    color: #1c5bfe !important
  }
  table td:last-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    width: 5px;
    background-color: var(--border-color, transparent);
    border-radius: 8px;
  }
  table.new-shipblu-table .table-row {
    -webkit-transform: translateY(0px) !important;
  }
  // TableLoader
  @mixin background-gradient {
    background-image: linear-gradient(45deg, #0A326630 0px, rgba(229, 229, 229, 0.8) 40px, #0A326630 80px);
  }
  .table-loader {
    th{
      background: rgba(220, 238, 255, 0.4);
      border-radius: 6px;
    }
    .line{
      @include background-gradient;
      animation: shine-lines 1s infinite alternate;
    }
  }
  @keyframes shine-lines {
    0% {background-position: 200px;}
    100% {background-position: -200px;}
  }
  div.table-checkbox {
    width: 100%; 
    z-index: 999999999; 
    pointer-events: all;
    .con-vs-checkbox {
      display: flex;
      justify-content: center !important;
    }
  }
  .vs-table--search{
    min-width: 500px;
  }
  @media (max-width: 576px) {
    table td:last-child::before {
      width: 0px;
    }
    .new-td {
      width: auto;
      white-space: nowrap;
      .transform-50 {
        top: 17px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
    div.table-checkbox .con-vs-checkbox {
      display: block !important;
    }
    table.new-shipblu-table .table-row{
      display: grid !important;
      gap: 16px 0px;
      grid-template-columns: auto auto;
      width: 100%;
      justify-content: stretch;
      border-bottom: solid;
      margin-bottom: 20px;
      background: #FFFFFF;
      border-style: solid;
      border-width: 1px;
      border-radius: 1rem;
      --border-opacity: 1;
      border-color: #dae1e7;
      border-color: rgba(218, 225, 231, var(--border-opacity)) /* #dae1e7 */;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom-width: 8px;
      padding: 18px 0px;
      max-width: 100%;
    }
    table.new-shipblu-table td {
      padding: 0px 19px;
      border-bottom: 0px solid #E5E5E5;
    }
    .vs-table--tbody-table {
      min-width: fit-content;
    }
    .header{
      display: none;
    }
    table.new-shipblu-table td:first-child::before {
      border-left: 0px solid;
    }
  }
  .new-shipblu-table {
    .vs-checkbox--input:checked+.vs-checkbox {
      border: 1.5px solid #1c5bfe !important;
    }
    .vs-checkbox-primary .vs-checkbox {
      border: 1.5px solid #3D3D3D !important;
    }
  }
  .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 9px;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    box-shadow: none !important;
    &::placeholder {
      color: #DCDCDC;
    }
  }
  @media (max-width:990px) {
    .vs-table--search {
    min-width: 100% !important;
   } 
  }
  @media (min-width: 576px) {
    .sm\:text-secondary {
      color: #9A9A9A;
    }
  }
  </style>