<template>
  <div>
    <p class="font-medium text-lg text-black mb-3">{{$t('More Information')}}
      <span v-if="type === 'Add Delivery Order'" class="font-normal sm:text-lg text-black">{{$t('(Optional)')}}</span>
    </p>
    <shipblu-card :cardLoading="infoLoading" class="md:p-6 p-5">
      <div v-if="showServiceLevel" class="mb-8">
        <p class="leading-tight mb-3 text-darkgray font-medium">{{ $t('Service Level') }}</p>
        <div class="flex gap-4 service-level">
          <div v-for="(serviceLevel, index) in serviceLevels" :key="index">
            <label @click="checkedItem(serviceLevel, serviceLevels)" class="checked-border cursor-pointer">
              <input v-model="shipment.serviceLevel" :class="shipment.serviceLevel.id === serviceLevel.id ? 'checked' : ''" :value="serviceLevel" type="radio" />
              <span class="py-2 pl-7 pr-3 rounded text-sm">{{ serviceLevel.name }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-5">
        <div class="col-span-1">
          <div class="flex gap-2 items-center mb-2">
            <span class="material-symbols-outlined text-icon text-lg">barcode</span>
            <p class="font-medium text-sm text-grey-dark">{{$t('Reference Number')}}</p>
          </div>
          <input autocomplete="nofill" class="w-full input-order" type="text" v-validate="'max:30'" :placeholder="$t('Add your order reference No.')" name="reference" v-model="shipment.merchant_order_reference">
          <p class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first('reference') }}</p>
        </div>
        <div>
          <div class="flex items-center gap-2 mb-2">
            <span class="material-symbols-outlined text-icon text-lg mt-1">chat</span>
            <p class="font-medium text-sm text-grey-dark">{{$t('Do you want to leave notes?')}}</p>
          </div>
          <textarea v-validate="type === 'Add Return' ? 'required' : ''" class="resize-none w-full input-order border-0" :placeholder="$t('The description should preferably be written in Arabic and include the products with their exact quantities.')" name="delivery notes" v-model="shipment.order_notes"/>
          <p class="text-danger text-sm" v-show="errors.has('delivery notes')">{{ errors.first('delivery notes') }}</p>
        </div>
      </div>
    </shipblu-card>
  </div>
</template>
<script>
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  props: ['shipment', 'serviceLevels', 'resetData', 'type', 'checkValidation'],
  data () {
    return {
      showServiceLevel: false,
      preferredDate: '',
      infoLoading: false
    }
  },
  methods: {
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    }
  },
  watch: {
    'resetData' (val) {
      if (val === true) {
        this.serviceLevels = []
      }
    },
    'checkValidation' () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('allowToCreateExtra', true)
        }
      })
    },
    'serviceLevels' (val) {
      val.forEach(item => {
        item.checked = false
        if (this.shipment.serviceLevel && item.id === this.shipment.serviceLevel.id) item.checked = true
      })
      this.shipment.serviceLevel = this.shipment.serviceLevel && this.shipment.serviceLevel.id ? this.shipment.serviceLevel : this.serviceLevels[0]
      this.showServiceLevel = true
    },
    'shipment.serviceLevel' (val) {
      if (this.serviceLevels) {
        this.shipment.serviceLevel = val
      }
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    if (this.shipment.serviceLevel && this.shipment.serviceLevel.id) {
      this.serviceLevels.forEach(item => {
        if (item.name === this.shipment.serviceLevel.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    }
    this.infoLoading = true
    setTimeout(() => {
      this.infoLoading = false
    }, 2000)
  }
}
</script>

<style lang="scss">
textarea {
  &::placeholder {
    font-weight: 500 !important;
  }
}

</style>