<template>
  <div>
    <head-component :noActions="true" :statusFilters="type === 'outbound' ? outboundStatusFilters : inboundStatusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadOrders"></head-component>
    <div v-if="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'" class="xl:float-left clearfix xl:w-1/2 mt-4 w-full">
      <confirm-ship-button class="mr-4 float-left whitespace-nowrap" :confirmReturn="confirmReturn" :selected="selected" @selected="selected = $event" @loadShipments="loadOrders"></confirm-ship-button>
      <download-fulfillment class="mr-4 float-left whitespace-nowrap" :totalRows="totalRows" :selected="selected"></download-fulfillment>
      <cancel-shipments-button class="mr-4 float-left whitespace-nowrap" :type="type" :selected="selected" @selected="selected = $event" @loadShipments="loadOrders"></cancel-shipments-button>
    </div>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="orders"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Order ID')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t(type === 'outbound' ? 'Outbound Linked Tracking Number' : 'Inbound Linked Tracking Number')}}</shipblu-th>
        <shipblu-th>{{$t('Products')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'merchant'">{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('No. of Items')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
        :to="{name: `${$store.state.AppActiveUser.userRole}-fulfillment-order-view`, params: { type: type, orderID: data[indextr].id}}">
          <shipblu-td class="order-11" :data="data[indextr].shipBluInventoryID">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              {{ data[indextr].id }}
            </div>
          </shipblu-td>

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].created">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t(type === 'outbound' ? 'Outbound Linked Tracking Number' : 'Inbound Linked Tracking Number')}}</p>
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td class="order-5 inactive-link" :data="data[indextr].linkedTrackingNumber">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Products')}}</p>
            <p v-on:click.stop>
              {{ data[indextr].customer.address.shipping_provider_metadata && data[indextr].customer.address.shipping_provider_metadata.shipblu && data[indextr].customer.address.shipping_provider_metadata.shipblu.order
                ? data[indextr].customer.address.shipping_provider_metadata.shipblu.order.tracking_number : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].products">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Products')}}</p>
            <p class="mb-1" v-for="item in data[indextr].products" :key="item.index">
              {{ item.product ? item.product.sku : '' }} ({{ item.quantity }})
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].products" v-if="$store.state.AppActiveUser.userRole !== 'merchant'">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="mb-1" v-for="(item, index) in data[indextr].products" :key="index">
              <span class="mb-1 block" v-for="element in item.product.locations" :key="element.index">
                [{{ element.location }}] [{{$t('Stored Quantity')}}  :{{ element.stored_quantity }}]
              </span>
              <span v-if="index < data[indextr].products.length - 1 && item.product.locations.length > 0" >----</span>
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].customerInfo">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
              </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('No. of Items')}}</p>
            {{ data[indextr].number_of_items }}
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-2" v-if="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
              <span class="material-symbols-outlined text-2xl">more_horiz</span> 
              <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="viewShipment(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('View')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                  v-if="type === 'outbound'"
                  :disabled="data[indextr].status === 'confirmed_to_ship'"
                    @click="updateTracking(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="BoxIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('Confirm to Ship')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                  v-if="type === 'outbound' && (data[indextr].status === 'confirmed_to_ship' || data[indextr].status === 'created')"
                    @click="cancelTracking(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon icon="XCircleIcon" svgClasses="w-4 h-4" class="self-center"/>
                      <span>{{$t('Cancel')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <add-order v-if="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor'" :orderModal="orderModal" :type="type" :order="order" @loadOrders="loadOrders" @orderModal="orderModal = $event"/>
  </div>
</template>

<script>
import ShipbluTable from '../../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../../layouts/components/ShipBluTd.vue'
import { sendFulfillmentRequest } from '../../../../http/axios/requestHelper'
import common  from '@/assets/utils/common'
import AddOrder from '../components/AddOrder.vue'
import HeadComponent from '../../components/Header.vue'
import ConfirmShipButton from '../components/ConfirmShipButton.vue'
import ShipbluPagination from '../../../../layouts/components/ShipbluPagination.vue'
import CancelShipmentsButton from '../../../admin/components/CancelShipmentsButton.vue'
import DownloadFulfillment from '../../../admin/fulfillment/components/DownLoadFulfillment.vue'

export default {
  props: ['orderModal', 'type', 'confirmReturn'],
  data () {
    return {
      common,
      order: {
        shipping_provider_metadata: 'shipblu',
        flags: [],
        customer: {
          address: {
            shipping_provider_metadata: {}
          }
        }
      },
      orders: [],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: this.$route.query.search ? this.$route.query.search : '',
      searchInProgress: false,
      searchedValue: ' ',
      outboundStatusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'on hold',
          value: 'on_hold'
        },
        {
          name: 'confirmed to ship',
          value: 'confirmed_to_ship'
        },
        {
          name: 'picked',
          value: 'picked'
        },
        {
          name: 'packed',
          value: 'packed'
        },
        {
          name: 'ready to ship',
          value: 'ready_to_ship'
        },
        {
          name: 'shipped',
          value: 'shipped'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        }
      ],
      inboundStatusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'confirmed to return',
          value: 'confirmed_to_return'
        },
        {
          name: 'received',
          value: 'received'
        },
        {
          name: 'completed',
          value: 'completed'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        },
        {
          name: 'failed to receive',
          value: 'failed_to_receive'
        }
      ],
      filters: [],
      tableLoader: false
    }
  },
  watch: {
    'searchVal' () {
      this.$router.push({
        query: {
          search: this.searchVal,
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadOrders()
      }
    },
    'orderModal' () {
      this.order = {
        shipping_provider_metadata: 'shipblu',
        flags: [],
        customer_governorate: '',
        customer_city: '',
        serviceLevel: {},
        cash_amount: 0,
        declared_value: 0,
        customer: {
          address: {
            shipping_provider_metadata: {}
          }
        }
      }
      this.$emit('orderModal', this.orderModal)
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadOrders()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadOrders()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadOrders()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrders)
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadOrders () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&status=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/${this.type}/${query}`, 'get', null, true,
        (response) => {
          this.orders = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    updateTracking (order) {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/${order.id}/tracking/`, 'post', {order: order.id, status: 'confirmed_to_ship'}, true,
        () => {
          this.loadOrders()
        }
      )
    },
    cancelTracking (order) {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/${order.id}/tracking/`, 'post', {order: order.id, status: 'cancelled'}, true,
        () => {
          this.loadOrders()
        }
      )
    },
    viewShipment (order) {
      this.$router.push({
        name: 'merchant-fulfillment-order-view',
        params: {
          orderID: order.id,
          type: this.type
        }
      }).catch(() => {})
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddOrder,
    HeadComponent,
    ConfirmShipButton,
    ShipbluPagination,
    CancelShipmentsButton,
    DownloadFulfillment
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadOrders()
  }
}
</script>

<style>
.con-pagination-table{
  display: none;
}
</style>